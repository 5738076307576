import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { Link } from 'components/Link';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import {
  APIKeyData,
  APIKeyLastAction,
  APIKeyStatus,
} from 'features/DataConnection/types';
import { PortalMemberName } from 'features/PortalMembers/PortalMemberName';
import { usePermission } from 'hooks/usePermission';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'shared/types/authorization';
import { differenceInDays } from 'utils/dateHandling.utils';

type APIKeyTableRowProps = {
  apiDetails: APIKeyData;
  showUserName: boolean;
  handleShowAPIDeleteKeyModal: () => void;
  key: string;
};

export const APIKeyTableRow: React.FC<APIKeyTableRowProps> = ({
  apiDetails,
  showUserName,
  handleShowAPIDeleteKeyModal,
}: APIKeyTableRowProps) => {
  const { t } = useTranslation();
  const currentDate = new Date().getTime();
  const expiringDate = new Date(apiDetails.dateExpiry).getTime();
  const daysRemaining = differenceInDays(expiringDate, currentDate);
  const isExpiring = daysRemaining <= 30 ? true : false;
  const { userHasPermission } = usePermission();

  const canDeleteKey =
    apiDetails.status === APIKeyStatus.ACTIVE &&
    userHasPermission(Permission.DATA_CONNECTION_DELETE_KEYS);

  return (
    <TableRow hoverable>
      {showUserName && (
        <TableCell>
          <PortalMemberName
            name={apiDetails.userDetails?.name}
            email={apiDetails.userDetails?.email?.toLowerCase()}
          />
        </TableCell>
      )}
      <TableCell>
        <div>{apiDetails.projectName}</div>
        {apiDetails.lastAction === APIKeyLastAction.MOVED && (
          <div>(transferred)</div>
        )}
      </TableCell>
      <TableCell>{apiDetails.scopes.join(', ')}</TableCell>
      <TableCell>
        {new Date(apiDetails.dateCreated).toLocaleString('en-GB', {
          day: '2-digit',
          month: 'numeric',
          year: 'numeric',
        })}
      </TableCell>
      <TableCell>
        {t(
          `features:data-connection:apiKeyManagement:keyStatus:${apiDetails.status}`,
        )}
      </TableCell>
      <TableCell>
        <div
          className={classNames('flex', {
            'font-semibold text-red-100': isExpiring,
          })}
        >
          {apiDetails.status === 'cancelled'
            ? '-'
            : new Date(apiDetails.dateExpiry).toLocaleString('en-GB', {
                day: '2-digit',
                month: 'numeric',
                year: 'numeric',
              })}
          {!!isExpiring && (
            <span className="w-20px h-20px my-auto ml-1 flex">
              <Icon
                name="InfoCircle"
                color="blue-200"
                tooltip={
                  daysRemaining >= 0
                    ? t(`features:data-connection:table:daysLeftTooltip`, {
                        daysLeft: daysRemaining,
                      })
                    : t('features:data-connection:table:isExpiredTooltip')
                }
              />
            </span>
          )}
        </div>
      </TableCell>
      <TableCell>{apiDetails.apiKey}</TableCell>
      <TableCell
        justify="end"
        isSticky
        position="last"
        bgColor="bg-white"
        boxShadow
      >
        {canDeleteKey && (
          <Link
            iconPosition="right"
            onClick={() => {
              handleShowAPIDeleteKeyModal();
            }}
            icon="Delete"
          />
        )}
      </TableCell>
    </TableRow>
  );
};
