import classNames from 'classnames';
import { Alignment } from 'components/Heading/Heading';
import { Link } from 'components/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { Icon, IconName } from '../Icon';
import './styles.css';

export interface ModalProps {
  cancelText?: string;
  children?: React.ReactNode;
  confirmText?: string;
  heading?: string;
  headingAlign?: Alignment;
  hideAllButtons?: boolean;
  icon?: IconName;
  isBig?: boolean;
  isCancelButtonHidden?: boolean;
  isCloseIconHidden?: boolean;
  isConfirmButtonAlignedRight?: boolean;
  isConfirmButtonDisabled?: boolean;
  isFullscreen?: boolean;
  isHidden?: boolean;
  isIconAlignedCenter?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}
export const Modal: React.FC<ModalProps> = ({
  cancelText,
  children,
  confirmText,
  heading,
  headingAlign,
  hideAllButtons,
  icon,
  isBig,
  isCancelButtonHidden,
  isCloseIconHidden,
  isConfirmButtonAlignedRight,
  isConfirmButtonDisabled = false,
  isFullscreen,
  isHidden,
  isIconAlignedCenter,
  onCancel,
  onConfirm,
}: ModalProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="modal-outer"
      className={classNames(
        `z-modal bg-grey-700t modal fixed inset-0 flex items-center justify-center overflow-auto backdrop-blur-lg`,
        {
          'modal-hidden-anime hidden': isHidden,
          'p-3': !isFullscreen,
        },
      )}
    >
      {/* This^ div is necessary for the modal to scroll correctly */}
      <div
        className={classNames(`max-h-full w-full max-w-full`, {
          'open-modal-anime': !isHidden,
        })}
      >
        <div
          className={classNames(`shadow-12dp relative mx-auto bg-white`, {
            'fullscreen-modal items-center': isFullscreen,
            'my-3 rounded': !isFullscreen,
            'max-w-730px': isBig,
            'max-w-550px': !(isBig || isFullscreen),
          })}
        >
          {!isCloseIconHidden && (
            <button
              data-testid="modal-cancel"
              className="text-grey-600 absolute right-0 top-0 mr-4 mt-4 h-3 w-3 self-end hover:text-blue-100 focus:text-blue-100"
              onClick={onCancel}
            >
              <Icon name="Close" />
            </button>
          )}
          <div
            className={classNames(`flex flex-col p-7`, {
              'items-center': !isBig,
            })}
          >
            {icon && (
              <div
                className={classNames(`mb-4 h-7`, {
                  'w-7': !isIconAlignedCenter,
                })}
              >
                <Icon name={icon} />
              </div>
            )}
            <div
              className={classNames(`mb-6 max-w-full`, {
                'items-center': !isBig,
                'max-w-content xl:min-w-content': isFullscreen,
              })}
            >
              {heading && (
                <Heading
                  level="h2"
                  text={heading}
                  align={headingAlign || 'center'}
                />
              )}
              {children ? <div>{children}</div> : null}
            </div>
            {!hideAllButtons && (
              <div
                data-testid="modal-bottom-buttons"
                className={classNames(`max-w-content flex w-full`, {
                  'justify-end': isConfirmButtonAlignedRight,
                  'justify-between': !isConfirmButtonAlignedRight,
                })}
              >
                {!isCancelButtonHidden && (
                  <div
                    className={classNames('mt-2', {
                      'mt-1 flex justify-center': !isBig,
                    })}
                  >
                    <Link
                      label={cancelText || t('labels:cancel')}
                      onClick={onCancel}
                    />
                  </div>
                )}

                <Button
                  label={confirmText}
                  onClick={onConfirm}
                  disabled={isConfirmButtonDisabled}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
