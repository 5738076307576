import { Modal } from 'components/Modal';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiKeyTransferEmailItem } from 'utils/firebase';

export const APIKeyTransferModal: React.FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'modals:apiKeyTransferModal',
  });
  const [isModalHiddenByUser, setIsModalHiddenByUser] = useState(false);

  const transferredKeysData = useMemo<
    { transferredBy: string; keyItems: ApiKeyTransferEmailItem[] } | undefined
  >(() => {
    const urlHashValue = window.location.hash.split('#')[1];
    if (!urlHashValue) return;
    try {
      return JSON.parse(decodeURIComponent(urlHashValue));
    } catch (err) {
      console.error(err);
      return;
    }
  }, []);

  const doesShowModal = Boolean(transferredKeysData) && !isModalHiddenByUser;

  if (!doesShowModal) return null;

  const singleOrMulti =
    transferredKeysData?.keyItems?.length === 1 ? 'single' : 'multi';

  const clearUrlHash = () => {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search,
    );
    setIsModalHiddenByUser(true);
  };

  return (
    <Modal
      isBig
      heading={t(`heading_${singleOrMulti}`, {
        apiKeysCount: transferredKeysData?.keyItems?.length,
      })}
      isConfirmButtonDisabled={true}
      hideAllButtons
      headingAlign="left"
      onCancel={clearUrlHash}
    >
      <div className="mb-5 mt-8">
        {t(`description_${singleOrMulti}`, {
          apiKeysCount: transferredKeysData?.keyItems?.length,
          transferredBy: transferredKeysData?.transferredBy,
        })}
      </div>
      {transferredKeysData?.keyItems.map(
        ({ projectName, transferredFrom, scopes, expiryDate }, index) => {
          return (
            <>
              <div className="mb-3 font-semibold">
                {t(`transferredApiKey_${singleOrMulti}`, {
                  count: index + 1,
                })}
              </div>
              <table
                cellPadding="0"
                cellSpacing="0"
                border={0}
                className="mb-6 w-full border-0"
              >
                <tr>
                  <th className="border-grey-300 w-200px border-t py-2 pr-3 text-left align-top">
                    {t('tableHeaders:projectName')}
                  </th>
                  <td className="border-grey-300 border-t py-2 align-top">
                    <div>{projectName}</div>
                  </td>
                </tr>
                <tr>
                  <th className="border-grey-300 w-200px border-t py-2 pr-3 text-left align-top">
                    {t('tableHeaders:transferredFrom')}
                  </th>
                  <td className="border-grey-300 border-t py-2 align-top">
                    <div>{transferredFrom}</div>
                  </td>
                </tr>{' '}
                <tr>
                  <th className="border-grey-300 w-200px border-t py-2 pr-3 text-left align-top">
                    {t('tableHeaders:scopes')}
                  </th>
                  <td className="border-grey-300 border-t py-2 align-top">
                    <div>{scopes}</div>
                  </td>
                </tr>
                <tr>
                  <th className="border-grey-300 w-200px border-t py-2 pr-3 text-left align-top">
                    {t('tableHeaders:expiryDate')}
                  </th>
                  <td className="border-grey-300 border-t py-2 align-top">
                    <div>{expiryDate}</div>{' '}
                  </td>
                </tr>
              </table>
            </>
          );
        },
      )}
    </Modal>
  );
};
