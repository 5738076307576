import classNames from 'classnames';
import { Icon } from 'components/Icon';
import React, { MouseEventHandler, ReactNode } from 'react';
import './style.css';

export type TableCellProps = {
  noWrap?: boolean;
  onClick?: MouseEventHandler;
  sortOrder?: 'asc' | 'desc' | undefined;
  align?: 'left' | 'center' | 'right' | undefined;
  justify?: 'start' | 'center' | 'end' | undefined;
  displaySortingButton?: boolean;
  colSpan?: number;
  smallTable?: boolean;
  smallHeaderTable?: boolean;
  customYPadding?: boolean;
  children: ReactNode;
  isSticky?: boolean;
  position?: 'first' | 'last';
  bgColor?: string;
  boxShadow?: boolean;
};

export const TableCell: React.FC<TableCellProps> = ({
  noWrap,
  onClick,
  sortOrder,
  align,
  justify,
  displaySortingButton,
  smallTable,
  smallHeaderTable,
  customYPadding,
  children,
  colSpan,
  isSticky,
  position,
  bgColor,
  boxShadow,
}: TableCellProps) => {
  return (
    <td
      className={classNames({
        'td-box-shadow': boxShadow,
        'px-3 py-4': !smallTable && !smallHeaderTable && !customYPadding,
        'pb-0 pl-3': smallTable,
        'py-1': smallHeaderTable,
        'pb-3 pt-2': customYPadding,
        'whitespace-nowrap': noWrap,
        'cursor-pointer': !!onClick,
        sticky: isSticky,
        'left-0': position === 'first',
        'right-0': position === 'last',
        [bgColor ?? '']: bgColor,
      })}
      onClick={onClick}
      colSpan={colSpan}
    >
      <div
        className={classNames({
          'flex flex-row ': displaySortingButton,
          [`text-${align}`]: align,
          [`flex justify-${justify} mr-0`]: justify,
        })}
      >
        {children}{' '}
        {displaySortingButton && (
          <div
            className={classNames('w-20px ml-1', {
              'text-grey-300': !sortOrder,
            })}
          >
            <Icon name={sortOrder === 'desc' ? 'ArrowNavUp' : 'ArrowNavDown'} />
          </div>
        )}
      </div>
    </td>
  );
};
