import { userRoleIsInRoleAllowedList } from 'lib/rolesAndPermissions';
import { compact } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Role } from 'shared/types/authorization';
import { extractRoleFromUser } from 'utils/authorization.utils';
import { viewUsers } from 'utils/firebase/cloud-functions';
import { UserData } from 'utils/firebase/collection';
import { useCurrentPrincipal } from './useCurrentPrincipal';
import { useCurrentUser } from './useCurrentUser';
import { useGlobalUsers } from './useGlobalUsers';
import { useRoleDefinitions } from './useRoleDefinitions';
import { BaseUseUserResult, PortalUser } from './useUser';

export type UseUsersResult = {
  users: BaseUseUserResult[] | undefined;
  setUsersAreUpdated: Dispatch<SetStateAction<boolean>>;
};

type UseUsersProps = {
  allInternalUsers?: boolean;
  disabled?: boolean;
};

/**
 * Custom Hook to get all users of the current principal and global users (if you have the permissions to get them)
 * @param allInternalUsers Fetches all internal users (only available with global_user rights)
 * @returns {UseUsersResult}, Array of users and setter if user data is changed to indicate an update of the userData
 */
export const useUsers = (props?: UseUsersProps): UseUsersResult => {
  const [principalUsers, setPrincipalUsers] = useState<PortalUser[]>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const { user, role } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const globalUserDocsTest = useGlobalUsers();
  const roleDefinitions = useRoleDefinitions();

  useEffect(() => {
    if (props?.disabled) return;

    setIsUpdated(false);
    viewUsers({
      currentPrincipal: currentPrincipal.id,
      allInternalUsers: props?.allInternalUsers,
    })
      .then(({ data }) => data)
      .then(
        async (
          data:
            | { success: boolean; result: UserData[] | UserData }
            | { code: string },
        ) => {
          if ('code' in data || ('success' in data && !data.success)) {
            console.error('Failed fetching users: ', data);
            return;
          }

          if (Array.isArray(data.result)) {
            const portalUsersOfCurrentPrincipal = data.result;

            if (props?.allInternalUsers) {
              setPrincipalUsers(data.result);
              return;
            }

            const globalUserAdminsOutsideOfCurrentPrincipal = globalUserDocsTest
              ? globalUserDocsTest?.filter((globalUser) => {
                  const isGlobalUserMemberOfCurrentPrinicpal =
                    portalUsersOfCurrentPrincipal?.find(
                      (portalMember) => portalMember.id === globalUser.id,
                    );
                  const globalUserData = globalUser.data.global_user;

                  return (
                    !isGlobalUserMemberOfCurrentPrinicpal &&
                    (globalUserData?.role === Role.GLOBAL_USER_ADMIN ||
                      (globalUserData?.principalRoles &&
                        Object.keys(globalUserData?.principalRoles).includes(
                          currentPrincipal.id,
                        )))
                  );
                })
              : [];

            const promises: Promise<PortalUser | undefined>[] = [];
            globalUserAdminsOutsideOfCurrentPrincipal.map((globalUser) => {
              promises.push(
                viewUsers({
                  currentPrincipal: currentPrincipal.id,
                  userIdToView: globalUser.id,
                })
                  .then(({ data }) => data)
                  .then(
                    (
                      data:
                        | { success: boolean; result: UserData[] | UserData }
                        | { code: string },
                    ) => {
                      if (
                        'code' in data ||
                        ('success' in data && !data.success)
                      ) {
                        console.error('Failed fetching users: ', data);
                        return;
                      }

                      if (!Array.isArray(data.result)) {
                        const portalUserData: PortalUser = {
                          ...data.result,
                        };

                        return portalUserData;
                      }

                      return;
                    },
                  ),
              );
            });

            const hasInternalUserViewingRights = userRoleIsInRoleAllowedList(
              role,
              [
                Role.AZELIS_VISITOR,
                Role.GLOBAL_USER_ADMIN,
                Role.GLOBAL_USER_EDIT,
                Role.GLOBAL_USER_VIEW,
                Role.PDM,
              ],
            );

            Promise.all(promises).then(
              (globalUsersWhichAreNotPartOfCurrentPrincipal) => {
                setPrincipalUsers([
                  ...portalUsersOfCurrentPrincipal,
                  ...compact(
                    hasInternalUserViewingRights
                      ? globalUsersWhichAreNotPartOfCurrentPrincipal
                      : [],
                  ),
                ]);
              },
            );
          }
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalUserDocsTest, isUpdated, props?.allInternalUsers]);

  const resultData = useMemo(() => {
    return principalUsers?.map((principalUser) => {
      const role =
        extractRoleFromUser(currentPrincipal.id, principalUser) ?? Role.NONE;

      return {
        user: principalUser,
        role,
        userType: roleDefinitions[role]?.user_type,
        isGlobalUser: !!principalUser.global_user,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPrincipal,
    principalUsers,
    globalUserDocsTest,
    props?.allInternalUsers,
  ]);

  return { users: resultData, setUsersAreUpdated: setIsUpdated };
};
