import { Modal } from 'components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';

type APIDeleteKeyModalProps = {
  onCancel?: () => void;
  onConfirm?: () => void;
  isHidden?: boolean;
};

export const APIDeleteKeyModal: React.FC<APIDeleteKeyModalProps> = ({
  onCancel,
  onConfirm,
  isHidden,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isBig
      confirmText={t(
        `features:data-connection:apiKeyManagement:keyDeletion:deleteKeyModal:confirmText`,
      )}
      heading={t(
        `features:data-connection:apiKeyManagement:keyDeletion:deleteKeyModal:heading`,
      )}
      headingAlign="left"
      isCloseIconHidden={false}
      isConfirmButtonAlignedRight={false}
      isConfirmButtonDisabled={false}
      isHidden={isHidden}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <span>
        {t(
          `features:data-connection:apiKeyManagement:keyDeletion:deleteKeyModal:description`,
        )}
      </span>
    </Modal>
  );
};
