import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { routes } from 'controllers/ContentController/Routes';
import { useCurrentPrincipal, useCurrentUser } from 'hooks/queries';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type ApiKeyManagementEmptyStateProps = {
  module: 'apiKeyGenerator' | 'apiKeyManagement';
  tab: 'keyHistory' | 'keyOverview';
  currentPrincipal?: string;
  hideButton?: boolean;
  onClick?: () => void;
};

export const ApiKeyManagementEmptyState: FC<
  ApiKeyManagementEmptyStateProps
> = ({ module, tab, hideButton, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user: currentUser } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(currentUser.id);
  const { portalMembersRoute } = routes(currentPrincipal.slug);

  const handleClick = () => {
    if (module === 'apiKeyManagement') {
      navigate(portalMembersRoute.url);
    } else {
      if (onClick) onClick();
    }
  };

  return (
    <div className="border-grey-300 flex flex-col items-center space-y-4 rounded border p-7">
      <span className="border-grey-300 h-20 w-20">
        <Icon name="EmptyMarket" />
      </span>
      <Heading
        text={t(`features:data-connection:${module}:${tab}:noData:heading`)}
        margin={0}
        level="h3"
      />
      <p className="text-grey-600 max-w-622px mt-4 text-center">
        {t(
          `features:data-connection:${module}:${tab}:noData:infoTextEmptyState`,
        )}
      </p>
      {!hideButton && (
        <Button onClick={handleClick}>
          {t(`features:data-connection:${module}:${tab}:noData:buttonText`)}
        </Button>
      )}
    </div>
  );
};
