import { NewUserDetails } from 'components/UserManagementModals';
import { ErrorSource } from 'features/PortalMembers/PortalMembers';
import { PortalUser } from 'hooks/queries';
import { TFunction } from 'i18next';
import { ContactFormData } from 'lib/mailGeneration';
import { Role } from 'shared/types/authorization';
import { Principal, UserData } from '../collection';

export type CalcMandatesPerUserRequest = {
  principalId: string;
  selectedMandatesFilter?: {
    hasAllMandates?: boolean;
    portfolio: string[];
    country: string[];
    marketSegment: string[];
    state: string[];
  };
  email?: string;
  userId: string;
};

export type InviteNewPortalMemberRequest = {
  newUser: NewUserDetails;
  selectedPrincipalId: string;
};

export type ApiKeyTransferEmailItem = {
  projectName: string;
  transferredFrom: string;
  scopes: string;
  expiryDate: string;
};

export type MailGenerationParameter = {
  t: TFunction<'translation', undefined, 'translation'>;
  emailType: EmailType;
} & (
  | InviteMailParameter
  | OnboardingErrorMailParameter
  | GeneralErrorMailParameter
  | ServiceDeskMailParameter
  | EdiContactMailParameter
  | ApiKeyTransferMailParameter
);

type InviteMailParameter = {
  emailType: EmailType.INVITE;
  data: {
    currentPrincipal: Principal;
    currentUser: PortalUser;
    newUser: NewUserDetails;
    inviteUrl?: string;
  };
};

type OnboardingErrorMailParameter = {
  emailType: EmailType.USER_ONBOARDING_ERROR;
  data: {
    currentUser: PortalUser;
    currentPrincipal: Principal;
    newUser: NewUserDetails;
    errorSource: ErrorSource;
    errorMessage: string;
  };
};

type GeneralErrorMailParameter = {
  emailType: EmailType.ERROR;
  data: {
    currentUser: PortalUser;
    errorSource: string;
    errorMessage: string;
  };
};

type ServiceDeskMailParameter = {
  emailType: EmailType.SERVICE_DESK;
  data: {
    currentPrincipal: Principal;
    currentUser: PortalUser;
    newUser: NewUserDetails;
  };
};

type EdiContactMailParameter = {
  emailType: EmailType.EDI_CONTACT;
  data: {
    currentPrincipal: Principal;
    currentUser: PortalUser;
    ediContactFormData: ContactFormData;
  };
};

type ApiKeyTransferMailParameter = {
  emailType: EmailType.API_KEY_TRANSFER;
  data: {
    sendTo: string;
    subject: string;
    transferredTo: string;
    transferredBy: string;
    keysTransferData: ApiKeyTransferEmailItem[];
    currentPrincipal: Principal;
  };
};

export enum EmailType {
  USER_ONBOARDING_ERROR = 'userOnboardingError',
  ERROR = 'error',
  INVITE = 'invite',
  SERVICE_DESK = 'serviceDesk',
  EDI_CONTACT = 'ediContact',
  API_KEY_TRANSFER = 'apiKeyTransfer',
}

type OnboardingErrorMail = {
  emailType: EmailType.USER_ONBOARDING_ERROR;
  content: Record<string, string>;
};

type GeneralErrorMail = {
  emailType: EmailType.ERROR;
  content: Record<string, string>;
};

type InviteMail = {
  emailType: EmailType.INVITE;
  sendTo: string;
  content: Record<string, string>;
};

type ServiceDeskMail = {
  emailType: EmailType.SERVICE_DESK;
  content: Record<string, string>;
};

type EDIContactMail = {
  emailType: EmailType.EDI_CONTACT;
  sendTo: string;
  content: Record<string, Record<string, string>>;
};

type ApiKeyTransferMail = {
  emailType: EmailType.API_KEY_TRANSFER;
  sendTo: string;
  content:
    | Record<string, string>
    | Record<string, Record<string, string>>
    | Record<
        string,
        Array<ApiKeyTransferEmailItem> | Record<string, string> | string
      >;
};

export type EmailRequest =
  | OnboardingErrorMail
  | GeneralErrorMail
  | InviteMail
  | ServiceDeskMail
  | EDIContactMail
  | ApiKeyTransferMail;

export type SuccessAndMessage = {
  success: boolean;
  code?: string;
  inviteRedeemUrl?: string;
  message: string;
};

export type HttpsError = {
  success: false;
  message: {
    code: string;
    details: {
      message: string;
      code: number;
      scope: string;
    };
    name: string;
    message: string;
  };
};

export type HttpsErrorDetailsMessageObject = {
  message: string;
  success: false;
};

export type CreateNewPortalUserRequest = {
  newUser: NewUserDetails;
  selectedPrincipal: string;
};

export type DeleteUsersRequest = {
  userIdsToDelete: string[];
  currentPrincipalId: string;
};

export type ViewUsersRequest = {
  currentPrincipal?: string;
  userIdToView?: string;
  allInternalUsers?: boolean;
};

export type ViewUsersResponse =
  | {
      success: boolean;
      result: UserData[] | UserData;
    }
  | { code: string };

export type EditUserRequest = {
  currentPrincipal: string;
  userIdToEdit: string;
  editedUserData: Partial<UserData>;
};

export type EditUserResponse =
  | {
      success: boolean;
    }
  | { code: string };

export type DeleteUsersResponse =
  | {
      success: boolean;
      result: {
        deleted: string[];
        notDelete: string[];
      };
    }
  | { code: string };

export type GetTokenResponse = {
  token?: string;
  error?: string;
  adUserId?: string;
};

export type GetEmbedTokenRequestParams = {
  reportName: string;
  userEmail: string;
  role: string;
};

export type BaseUserType = {
  email: string;
  firstName: string;
  lastName: string;
};

export type AdUserType = {
  name: string;
  email: string;
  guid: string;
};

export type FirebaseTokenDataConnectApiRequest = {
  currentPrincipal: string;
  userEmail: string;
  userRoleOnCurrentPrincipal: Role;
};

export type FirebaseTokenForDataConnectionApiParams = {
  currentPrincipal: string;
};

export type CRMStateData = {
  id: string;
  label: string;
};

export type MappedCountry = {
  id: string;
  iso: string;
  name: string;
};

export type MappedMarketSegment = {
  ibaid: string;
  id: string;
  name: string;
};

export type MappedPortfolio = {
  name: string;
  id: string;
};

export type MappedMandates = {
  count: {
    countries: number;
    mandates: number;
    marketSegments: number;
    portfolios: number;
  };
  countries: MappedCountry[];
  marketSegments: MappedMarketSegment[];
  portfolios: MappedPortfolio[];
};

export type CRMMandatesPayload = {
  filter?: {
    hasAllMandates?: boolean;
    iso?: string[];
    marketSegment?: string[];
    portfolio?: string[];
  };
  principalPri: string;
};
