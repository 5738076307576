import { Permission } from 'shared/types/authorization';

export default {
  translation: {
    placeholders: {
      notApplicable: 'Not applicable',
      placeholder: 'Placeholder',
      dropdownDefault: 'Select',
      typeToFilter: 'Type to filter',
      textareaDefault: 'Enter here',
      dropdownAll: 'All',
      dropdownNone: 'None selected',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
      transferKeysTo: 'transfer keys to',
    },
    resultsWithCount_one: 'result',
    resultsWithCount_other: 'results',
    labels: {
      loading: 'Loading',
      signIn: 'Log In',
      signOut: 'Log Out',
      collapseMenu: 'Collapse Menu',
      expandMenu: 'Expand Menu',
      multiSelectHint: 'Multiple selections possible',
      other: 'Other',
      noOptions: 'No options',
      or: 'or',
      // e.g. 'Created at 12:00am 12.12.2021'
      createdAt: 'Created at',
      status: 'Status',
      continue: 'Continue',
      portalName: 'Partner Area',
      unknown: 'Unknown',
      about: 'About',
      contact: 'Contact',
      cancel: 'Cancel',
      delete: 'Delete',
      search: 'Search',
      userNameSearch: 'Search by the user’s full name',
      name: 'Name',
    },
    linksOrButtons: {
      details: 'Details',
      // e.g. 'Back to Applications'
      back: 'Back',
      backTo: 'Back to',
      seeProjectOverview: 'See project overview',
      backToOverview: 'Back to overview',
      backToReview: 'Jump to review',
      backToPortalMembers: 'Back to portal members',
      showMore: 'Show more',
      showLess: 'Show less',
      startNewFormulation: 'Start new formulation',
      toggleMenu: 'Toggle Menu',
      navigation: {
        start: 'Start working',
        dashboard: 'Dashboard',
        'sales-report': 'Sales Report',
        performance: 'Performance',
        'market-intelligence': 'Market Intelligence',
        'api-documentation': 'API Documentation',
        'shared-documents': 'Shared Documents',
        'digital-activities': 'Digital Activities',
        account: 'My Account',
        'portal-members': 'Portal Members',
        'global-users': 'Global Users',
        'data-connections': 'Data Connections',
        'partner-area': 'API Key Generator',
      },
      backToStart: 'Start',
      edit: 'Edit',
      next: 'Next',
      sendBriefing: 'Send briefing',
      showXMore: 'Show {{number}} more',
    },
    errors: {},
    // 💡 Note for devs:
    //  Strings that generally related to their topics. They are used multiple times in steps, review, project details, or email.
    topics: {},
    modals: {
      deleteUploadedFile: {
        prompt: 'Do you want to delete the file?',
        confirm: 'Yes, delete',
      },
      account: {
        heading: 'Your Account',
        name: 'Name',
        email: 'Email address',
        selectedMarket: 'Selected market',
      },
      changeCustomerSelection: {
        prompt:
          'Please select the Principal you wish to see from the dropdown:',
        confirm: 'Confirm',
      },
      addInsightModal: {
        heading: 'Add insight',
        subline: '',
        publish: 'Publish',
        title: 'Title',
        titlePlaceholder: 'Write a short and clear title',
        content: 'Content',
        contentPlaceholder: 'Write the content of the insights',
        category: 'Category',
        categoryPlaceholder: 'Select at least one category',
        addLink: 'Add link',
        link: 'Link',
        linkPlaceholder: 'http://www.azelis.com',
        linkLabel: 'Link label',
        linkLabelPlaceholder: 'This is a Link with useful information',
        marketSegment: 'Market segment',
        marketSegmentPlaceholder: 'Select one market segment',
        country: 'Country',
        countryPlaceholder: 'Select at least one relevent country',
        errors: {
          title:
            'Please provide a title between 10 and 60 characters! Currently: {{length}}.',
          content:
            'Please provide a text between 15 and 500 characters! Currently: {{length}}.',
          categories: 'Please select at least one category!',
          marketSegements: 'Please select a market segment!',
          country: 'Please select a country',
          link: 'Please provide a valid link!',
          linkLabel:
            'Please provide a label with at least 5 characters! Currently: {{length}}.',
        },
      },
      editInsightModal: {
        tooltip: 'Edit insight',
        heading: 'Edit insight',
        publish: 'Save',
      },
      deleteInsightModal: {
        tooltip: 'Delete insight',
        heading: 'Are you sure, that you want to delete the insight?',
        confirm: 'Delete',
      },
      addUserModal: {
        heading: 'Create new user',
        description:
          'This process will add a new user for this principal. The user will also be created in Active Directory, unless they already exist.',
        description_warning:
          'The user cannot log in unless their account exists in Active Directory.',
        personalDetailsHeading: 'Enter your users details',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        role: 'Role',
        roleHeading: '2. Select a role',
        userSettingsHeading: '2. Set user preferences',
        currency: 'Currency',
        weightMetrics: 'Weight metrics',
        mandateSelectionHeading: 'Add mandates',
        all: 'All',
        portfolio: 'Portfolio 1 / Portfolio 2',
        country: 'Country',
        marketSegment: 'Market Segment',
        confirm: 'Create user',
        errors: {
          invalidEmail: 'Please enter a correctly formatted email address',
        },
        toggleLabel: 'Give access to all mandates',
        editMandateDescription:
          'To edit your mandates please click the “Edit user” button in the upper right corner',
        hasAllMandatesDescription:
          'You have access to all current and future mandates of this principal until turned off again',
        numberOfSelectedMandates_one:
          'Your selection below provides {{count}} mandate for this user',
        numberOfSelectedMandates_other:
          'Your selection below provides {{count}} mandates for this user',
        editHasAllMandatesDescription:
          'This grants access for all current and future mandates, until turned off again',
        editUserIsGlobalUser:
          'With your current user role, you have access to all current mandates, as well as all principals on the portal.',
        createUserMessage: 'Your changes are being saved.',
        waitingMessage: 'Please wait a bit',
        status: {
          start_invitation: 'Sending an invitation to the new user...',
          request_service_desk_setup:
            'Requesting manual user setup from the Azeis service desk...',
          start_creation: 'Creating new user record...',
        },
        infoToCreateInternalUser:
          'If you want to create a user without creating them in AD, use the domain @dontaddtoazelisad.com for the email address. This message will only display on local or development environments',
      },
      editPreferencesModal: {
        save: 'Save',
        edit: 'Edit Preferences',
        labels: {
          currency: 'Currency',
          weight: 'Weight',
        },
      },
      selectUserTypeModal: {
        heading: 'Do you want to invite an Azelis user or principal user?',
        labels: {
          internalOption: 'Azelis user',
          externalOption: 'Principal user',
        },
      },
      selectInternalUser: {
        heading: 'Invite an Azelis user',
        emptyStateMessage:
          "No users found for the search term '{{searchTerm}}'",
      },
      digitalActivitiesModal: {
        heading: 'Set your market settings',
        description:
          'Please select your preferred market by choosing a country, the respective market segment and the language you want to see.',
        confirm: 'Confirm',
        labels: {
          country: 'Country',
          marketSegment: 'Market segment',
          language: 'Language',
        },
        placeholders: {
          select: 'Select',
        },
      },
      editUserModal: {
        heading: 'Edit user',
        name: 'Name',
        email: 'Email',
        userPreferences: 'Preferences',
        currency: 'Currency',
        weight: 'Weight',
        description:
          'Select a new role for this user. Their account permissions will immediately change to reflect their new role.',
        role: 'Role',
        confirm: 'Save Changes',
        errors: {
          invalidEmail: 'Please enter a correctly formatted email address',
          alertADMatch:
            'Please ensure that the email here in the portal is identical to the email in Active Directory. If they are not the same, the user will not be able to log in to the portal.',
        },
        savingMandateAlert:
          'Changes to the mandate settings will be reflected in the Portal starting from the next morning',
        savingMandateMessage: 'Your changes are being saved.',
        waitingMessage: 'Please wait a bit',
      },
      countryModal: {
        azelisRegion: 'Azelis Region',
        cluster: 'Cluster',
        localCapabilitiesInCountry: 'Local capabilities in {{countryName}}',
        potentialForCollaboration: 'Potential for collaboration',
        localContact: 'Your local contact',
        numberOfOffices: 'Number of offices',
        numberOfStaff: 'Number of staff',
        consolidationOpportunity: 'Consolidation Opportunity',
        newCountryOpportunity: 'New country Opportunity',
        numberOfInnovationLabs: 'Number of innovation labs',
        availableMarketSegments: 'Available market segments in {{countryName}}',
        principalAndAzelisInCountry:
          '{{selectedPrincipal}} & Azelis in {{selectedCountry}}',
        representedPortfolios: 'Represented portfolios',
        representedMarketSegments: 'Represented market segments',
        mandateBlurb:
          'Our collaboration includes the distribution of your product portfolios to our customers from the following market segments.',
        russianIntro:
          'For the time being, we will continue to operate in Russia, but limited to essential businesses in the food, pharma, agro and hygiene sectors, securing basic human needs. In our operations, we will make sure to adhere to all international sanctions and embargoes with immediate effect or upon announced effective dates. We are routinely updating our sanctions control measures to ensure our consistent compliance with international law.',
        countryServedBy: 'This country is served by {{servedByName}}',
      },
      deleteUsersModal: {
        title: {
          single: 'Do you really want to permanently delete this user?',
          multi: 'Do you really want to permanently delete the selected users?',
        },
        description: {
          internal: {
            single:
              'You are about to remove the user "{{userNameOrNumber}}" from the Azelis Partner Portal for {{selectedPrincipalName}}. If you confirm this, they will no longer be able to log in to the Azelis Partner Portal (unless they have access to other Principals as well). This action cannot be revoked. Please make sure to remove users that have access to multiple Principals from each Principal individually.',
            multi:
              'You are about to remove {{userNameOrNumber}} users from the Azelis Partner Portal for {{selectedPrincipalName}}. If you confirm this, they will no longer be able to log in to the Azelis Partner Portal (unless they have access to other Principals as well). This action cannot be revoked. Please make sure to remove users that have access to multiple Principals from each Principal individually.',
          },
          global: {
            single:
              'You are about to remove the user "{{userNameOrNumber}}" from the Azelis Partner Portal for {{selectedPrincipalName}}. If you confirm this, they will no longer be able to log in to the Azelis Partner Portal (unless they have access to other Principals as well). This action cannot be revoked. Please make sure to remove users that have access to multiple Principals from each Principal individually.',
            multi:
              'You are about to remove {{userNameOrNumber}} users from the Azelis Partner Portal for {{selectedPrincipalName}}. If you confirm this, they will no longer be able to log in to the Azelis Partner Portal (unless they have access to other Principals as well). This action cannot be revoked. Please make sure to remove users that have access to multiple Principals from each Principal individually.',
          },
          external: {
            single:
              'You are about to remove the user "{{userNameOrNumber}}" from the Azelis Partner Portal for {{selectedPrincipalName}}. If you confirm this, they will no longer be able to log in to the Azelis Partner Portal. This action cannot be revoked.',
            multi:
              'You are about to remove {{userNameOrNumber}} users from the Azelis Partner Portal. If you confirm this, they will no longer be able to log in to the Azelis Partner Portal. This action cannot be revoked.',
          },
        },
        deleteWithoutTransferringKeys: 'Delete without transferring keys',
        noKeysToTransfer: 'no keys to transfer',
        additionalDeveloperDescription:
          'When deleting a developer from the portal you can transfer all of their created API keys to another developer by selecting them below to transfer the keys to them so they will not be lost.',
        info: 'To ensure that everything works without errors, please make sure that you also delete the user from Azure Active Directory.',
        deleteUserWithoutTransferringKeysAlert:
          'Deleted keys can not be retrieved again',
        allUserCount: {
          single:
            'You have currently selected {{numberOfUsersToDelete}} user to be deleted',
          multi:
            'You have currently selected {{numberOfUsersToDelete}} users to be deleted',
        },
        nonDeveloperUserCount: {
          single: '{{numberOfNonDevelopersToDelete}} user:',
          multi: '{{numberOfNonDevelopersToDelete}} users:',
        },
        developerUserCount: {
          single: '{{numberOfDevelopersToDelete}} developer:',
          multi: '{{numberOfDevelopersToDelete}} developers:',
        },
        confirm: {
          single: 'Delete user',
          multi: 'Delete users',
        },
        success: {
          single: "'{{data}}' has been deleted",
          multi: '{{data}} users have been deleted',
        },
        error: {
          system:
            'This operation could not be completed. Please contact your system administrator',
          notOnThisPrincipal:
            "'{{undeletedUser}}' could not be deleted, as they don't exist on this principal",
        },
      },
      contactFormModal: {
        heading: 'Get in contact with us',
        sendContactInfo: 'Contact us',
        description:
          'Please fill out this form in order to provide us with your EDI requirements. After contacting us, we will reach out to you with all details shortly.',
        ediProcessSelection: {
          heading: '1. In which automated EDI process are you interested in?',
          subHeading: '(Multiple selections possible)',
          optionOne: 'Automatic Purchase Order Creation',
          optionTwo: 'Automatic import of supplier e-invoices',
          other: 'Other',
          textFieldPlaceholder:
            'Please specify the EDI process you are interested in',
        },
        countriesSelection: {
          heading:
            '2. For which countries do you want to establish an EDI process with us?',
          textBoxHeading: 'Countries',
          textFieldPlaceholder:
            'List all the countries you’re interesting in establishing an EDI process with us',
        },
        technicalTeamSelection: {
          heading:
            '3. Please enter the names of your technical teams who should be involved in the initial call',
          textFieldMessage:
            'List all the countries you’re interesting in establishing an EDI process with us',
          nameFields: {
            name: 'Name',
            firstName: 'First Name',
            lastName: 'Last Name',
          },
          emailField: {
            email: 'Email',
            emailExample: 'email@mail.com',
            errorMessage: 'invalid email address',
            addEmailButton: 'add',
          },
          addedMembers:
            'Your have added {{numberOfAddedMembers}} team members to join the call:',
        },
        additionalInfo: {
          heading: 'Additional Information',
          subHeading: '(optional)',
          additionalInfoPlaceholder:
            'Enter further info or proposed time slots',
        },
        notification: {
          processing: 'Sending request...',
          success: 'Request sent sucessfully.',
          failed:
            'An error occured while sending the request. Please try again.',
        },
      },
      apiKeyTransferModal: {
        heading_single: 'An API key has transferred to you.',
        heading_multi:
          '{{apiKeysCount}} API keys have been transferred to you.',
        description_single:
          'An API key has been transferred to you by {{transferredBy}}.',
        description_multi:
          '{{apiKeysCount}} API keys have been transferred to you by {{transferredBy}}.',
        transferredApiKey_single: 'Summary of the transferred API key',
        transferredApiKey_multi: '{{count}}. Transferred API Key',
        tableHeaders: {
          projectName: 'Project name',
          transferredFrom: 'Previous key owner',
          scopes: 'Scopes',
          expiryDate: 'Date expiry',
        },
      },
    },
    components: {
      error404: {
        heading: 'Page not found',
        body: "We couldn't find anything at that URL. Try navigating using the menu or double-checking your link.",
      },
      fileUpload: {
        fileListLabel: 'Files attached',
        privacy:
          'Your data uploads are completely safe and will not be shared with any third parties. They will only be used by Azelis in the project context to assure the best outcomes for you.',
      },
      footer: {
        partOfAzelis: 'Part of the Azelis Customer Portal',
        copyright: 'Copyright © Azelis {{year}}. All Rights Reserved.',
        links: {
          cookie: 'Cookie Statement',
          privacy: 'Privacy Statement',
          terms: 'Terms of Use',
        },
      },
      powerBiReport: {
        status: {
          loading: 'Loading Report Assets',
          authenticating: 'Authenticating with Power BI',
          loadingEmbedInfo: 'Fetching Report Information',
          rendering: 'Rendering report',
          settingState: 'Loading persisted state',
          error: 'Something went wrong while loading the report',
        },
        save: 'Save report view',
        restore: 'Restore saved report view',
        reset: 'Reset report view',
        warning: {
          smallViewport:
            'Reports are currently not optimized for mobile devices.',
        },
        notification: {
          save: 'Report view saved.',
          restore: 'Report view restored.',
          reset: 'Report view successfully reset.',
          undo: 'Undo',
        },
      },
      principalSelectionBar: {
        change: 'Change',
        onBehalf: 'Principal:',
        allPrincipals: 'All principals',
      },
      sharepoint: {
        buttons: { download: 'Open', back: 'BACK' },
        table: {
          headers: {
            filename: 'Name',
            lastModified: 'Last modified',
            owner: 'Owner',
          },
        },
        emptyFolder: 'This folder is empty.',
      },
      permissionDenied:
        "Sorry, you don't have permission to access this feature.",
      roleSelector: {
        roleHasPermissions: 'The role of {{role}} has the following rights:',
        permissions: {
          [Permission.DASHBOARD_VIEW]: 'Can see Dashboard',
          [Permission.MARKET_INSIGHTS_VIEW]: 'Can see Market Insights',
          [Permission.EXPANSION_OPPORTUNITIES_VIEW]:
            'Can see Expansion Opportunities',
          [Permission.SALES_REPORTING_VIEW]: 'Can see Sales Reporting',
          [Permission.SHARED_DOCUMENTS_VIEW]: 'Can see Shared Documents',
          [Permission.OPERATIONAL_PERFORMANCE_VIEW]: 'Can see Performance View',
          [Permission.MARKET_INSIGHTS_EDIT]: 'Can edit Market Insights',
          [Permission.USER_ROLE_MGMT_EDIT]: 'Can edit User roles',
          [Permission.USER_ROLE_MGMT_VIEW]: 'Can view other Users',
          [Permission.SUPPLIER_PAGES_VIEW]: 'Can see Supplier Pages',
          [Permission.CUSTOMER_PORTAL_INSIGHTS_VIEW]:
            'Can see Customer Portal Insights',
          [Permission.EDIT_GLOBAL_USERS]: 'Can create and edit global Users',
          [Permission.DATA_CONNECTION_DEVELOPER_SCREENS]:
            'Can access API Key Generator',
          [Permission.DATA_CONNECTION_DELETE_KEYS]: 'Can delete API Keys',
          [Permission.DATA_CONNECTION_API_MANAGEMENT]: 'Can view API Keys',
        },
      },
      visibilityBadge: {
        text: 'only visible to azelis users',
      },
      reportPreview: {
        preview: 'Preview report',
        previewSmallAlt:
          'A small preview of the report. Click the following link to fully view the image.',
        previewMainAlt:
          'A full-sized image of the report. A PDF can be downloaded in the nearby archives table.',
        download: 'Download',
        imageError:
          'It looks like there was an error loading this report. You can try the following two options:',
        imageErrorSuggestions: {
          '1': 'Open one of the archived PDFs below, then refresh this page.',
          '2': 'Try a different browser, like Google Chrome or Microsoft Edge.',
        },
      },
      promoBannerDataConnection: {
        heading: 'New Data Connections Area',
        description:
          'Welcome back! We have just launched the Data Connections Area for our partner portal accessible in the left-hand menu bar. Here you can find all the information on how to connect digitally with Azelis. The first feature we have enabled is EDI, showcasing our end-to-end connections for back-office transactions and how to set these up. More exciting features are coming soon!',
        buttonLabel: 'Go to Data Connections Area',
      },
      promoBannerPerformance: {
        heading: 'Enhanced Performance Data',
        description:
          'Welcome back! We have just launched new insightful reports in the Performance section. There you can find the latest data around sales, opportunities and supply chain to make data-driven decisions. With more structured reports and enhanced data insights you can monitor your current business state and sales opportunity pipeline in a comprehensive way. Explore the new reports today!',
        buttonLabel: 'Go to Performance Area',
      },
    },
    features: {
      intro: {
        heading: {
          main: 'Transparent growth – with the Azelis Partner Portal',
          about: 'About the Partner Portal',
        },
        content: {
          main: 'We offer you the smartest way to manage your distribution business and act on customer insights.',
          benefits: [
            'Full visibility on your business',
            'Personalized market and country insights',
            'Easy communication and collaboration',
            'Accessible 24/7',
          ],
        },
      },
      dashboard: {
        heading: 'Dashboard',
        maintenence_warning_title:
          'Database Maintenance Extended Until Dec 1st',
        maintenence_warning_message:
          'Please be aware that we are currently migrating our database for improved performance. The data below was valid up until the 16th October. The migration was extended until December 1st. Starting then, the data will be refreshed on a daily basis.',
        welcome: 'Welcome back, {{name}}!',
        welcome_no_name: 'Welcome back!',
        updated_files_intro:
          ' The following items were updated while you were away:',
        updated_files_count: '{{count}} new files were placed for you',
        'pdm-contact': 'Your PDM: {{name}}',
        latestInsights: 'Latest Market Insights',
        yourContact: 'Your Direct Contact',
        expansionOpportunityCard: 'Top 3 expansion Opportunities',
        linkLabel: 'Show all',
        linkLabelReport: 'Go to Sales Report',
        portfolioLabel: 'Portfolio: ',
        marketSegmentLabel: 'Market Segment: ',
        map: {
          heading: 'Coverage map',
          generalInfo: 'General information about {{principal}} mandates',
          world: 'Worldwide',
          removeCountry: 'remove country selection',
          loading: 'Loading coverage map',
          legend: {
            azelis: 'Azelis countries',
            mandates: 'Current mandates',
            opportunities: 'New country opportunities',
            consolidation: 'Consolidation opportunities',
          },
          showOnMap: 'Show on map',
          managingDirector: 'Managing Director',
          mandateInfoEMEA:
            'Currently, the Principal Portal for {{principalName}} will focus on EMEA mandates only. Further regions will follow in the second roll-out phase.',
          region: 'Region:',
          mapNames: {
            WORLD: 'Worldwide',
            USA: 'United States',
            CANADA: 'Canada',
            EUROPE: 'Europe',
            LATINAMERICA: 'Latin America',
            NORTHAMERICA: 'North America',
            AFRICA: 'Africa',
            ASIA: 'Asia',
            MIDDLEEAST: 'Middle East',
            OCEANIA: 'Oceania',
          },
        },
        tiles: {
          countries: 'Countries',
          mandates: 'Mandates',
          portfolios: 'Portfolios',
          marketSegments: 'Market Segments',
          expansionOpportunities: 'Expansion Opportunities',
        },
      },
      reports: {
        sales_report_heading: 'Sales Reports',
        operations_heading: 'Performance',
        key_sales_numbers: 'Key sales numbers',
      },
      'digital-activities': {
        heading: 'Digital Activities',
        emptyMarket: 'This area is empty. Please select your market first.',
        noAvailableData:
          "This principal doesn't seem to be properly configured.",
        selectMarketNow: 'Select market now',
        digitalActivities: {
          tab: 'CP Insights',
          headline: 'CP Insights',
        },
        supplierPages: {
          tab: 'Supplier Pages',
          insight:
            'This page gives you the opportunity to explore your presence on the Supplier page within the Azelis Customer Portal.',
          headline: 'Supplier Pages',
          subheading: 'Page cannot be displayed',
          paragraph:
            'This page cannot be displayed with your current browser. In order to view this page, please use Google Chrome, Microsoft Edge or Mozilla Firefox browser.',
        },
        configBar: {
          country: 'Country',
          marketSegment: 'Market Segment',
          language: 'Language',
          editMarket: 'Change market',
          noMarket: 'There is no market selected',
          selectMarket: 'Select market',
        },
      },
      'data-connection': {
        heading: 'Data Connections',
        startMessage: 'How do you want to start?',
        ediInfo: {
          tile: {
            title: 'Azelis EDI Capabilities',
            description:
              'End-to-End transactional connectivity for back-office functions such as purchase orders and invoicing between yourselves and Azelis.',
          },
          ediPage: {
            getStarted: 'Get Started',
            title: 'Azelis EDI information',
            description:
              'Streamline your business processes and unlock growth opportunities with Electronic Data Interchange (EDI) integration. Fill out our quick and easy form to start the conversation.',
            entrySentence:
              'Transactional connectivity where we can connect with your back office to support end to end automation.',
            exitSentence:
              'If you have other EDI requirements please contact us, we have a team in place to facilitate automation and connectivity with our strategic partners.',
            whatisEDI: {
              headline: 'What is EDI?',
              content:
                'Electronic data interchange (EDI) is the concept of businesses electronically communicating information that was traditionally communicated on paper, such as purchase orders and invoices. Technical standards for EDI exist to facilitate parties transacting such instruments without having to make special arrangements. This also means it is no longer necessary to create paper-based invoices for example.',
            },
            benefitsofEDI: {
              headline: 'Benefits of EDI',
              content:
                'Much operational effort that is put in the alignment and communication to those parties can be automated digitally. If you avoid the manual execution of the order handling, there is a considerable gain in operational efficiency. This has the following benefits:',
              bulletPoint1: 'Faster order handling',
              bulletPoint2:
                'Order processing people can focus on exception management and principal/customer care',
              bulletPoint3:
                'Fewer errors need to be corrected later on in the process (less burden on the finance department)',
            },
            currentEDICapabilities: {
              headline: 'Azelis’ current EDI capabilities for you',
              content:
                'Azelis supports the transactional integration with a variety of formats. The following processes have been implemented for our principal and customer integration. A benefit for you as a supplier is that we can enable this in multiple countries at once because we have an integrated ERP. These flows are currently available in all EMEA and APAC countries where we operate:',
              bulletPoint1: 'Automatic purchase order creation (Outbound)',
              bulletPoint2: 'Automatic import of supplier e-invoices (Inbound)',
            },
          },
        },
        apiKeyGenerator: {
          heading: 'Azelis API Key Generator',
          description:
            'Welcome to our developer portal! Here, you can easily create projects and generate API keys specific to each project. Our platform offers a user-friendly experience, empowering you to streamline your workflow and seamlessly integrate APIs into your applications.',
          tabs: {
            keyOverview: 'Key Overview',
            keyHistory: 'Key History',
          },
          keyOverview: {
            projects: 'Projects',
            heading: 'No projects have been created yet.',
            buttonText: 'Add new project',
            infoTextEmptyState:
              'In order to generate an API key you will have to create a new project',
            searchFilter: {
              placeholder: 'Search for project name',
            },
            emptyFilters: {
              heading: 'No API Keys match your filters.',
              infoTextEmptyState: 'Please modify your filter selection.',
            },
            noData: {
              heading: 'No Projects have been created yet.',
              buttonText: 'Add new project',
              infoTextEmptyState:
                'In order to Generate API Key you will have to create a new project ',
            },
          },
          keyHistory: {
            noData: {
              heading: '',
              infoTextEmptyState:
                'Currently, there is no key history available to display.',
            },
            emptyFilters: {
              heading: 'No history items match your filters.',
              infoTextEmptyState: 'Please modify your filter selection.',
            },
          },
          apiModal: {
            step1: {
              heading: 'Add new Project',
              description:
                'Please select which API you are interested in accessing. Multiple selections are possible.',
              readOnlyNotice:
                'Currently we are only offering read access to our APIs.',
              chooseApi: 'Choose one or multiple APIs',
              projectInputHeader: 'Project Name',
              projectInputPlaceholder: 'Enter project name',
              proceed: 'Generate Key',
            },
            step2: {
              heading: 'Here is your API key',
              securityWarning: {
                part1: 'For security, ',
                part2:
                  ' this will be the last time the full key will be displayed.',
              },
              copyNotice:
                'In order to save the key click the copy icon next to API Key and paste it in a safe place on your local machine. If something happens you can always generate a new key.',
              APICopied: 'I have copied and stored the key securely',
              inputHeader: 'API key',
              projectInputPlaceholder: 'Enter project name',
              proceed: 'I have copied and stored the key securely',
              copiedNotification: 'Key copied to clipboard',
            },
            errors: {
              'Request failed with status code 404':
                'An error occured whilst fetching required data. Please try again later.',
              'Network Error':
                'An error occured whilst fetching required data. Please try again later.',
            },
          },
        },
        apiKeyManagement: {
          tile: {
            title: 'API Management',
            description:
              'Provide access to your developers for connecting our data with your internal systems through an API.',
          },
          heading: 'API Management',
          description:
            'Azelis provides a secure and reliable connection to access your data from our Partner Portal via API data connections. This eliminates the need for manual data extraction, reduces the risk of errors and allows you to effortlessly integrate the data to your business intelligence or commercial systems. Using our API management area you can invite your internal data team as developer users to create API keys and start accessing the APIs.',
          banner: {
            message:
              'To invite and edit developer users please head to the portal members page',
            buttonText: 'Go to portal members',
          },
          tabs: {
            keyOverview: 'Key Overview',
            keyHistory: 'Key History',
          },
          keyOverview: {
            noData: {
              heading: 'No API Keys have been created yet.',
              buttonText: 'Go to Portal Members page',
              infoTextEmptyState:
                'Please invite your internal technical resources as "Developers" in the Portal Members Page to create keys. Once a developer has created a key it will show up here for easy management and overview.',
            },
            emptyFilters: {
              heading: 'No API Keys match your filters.',
              infoTextEmptyState: 'Please modify your filter selection.',
            },
            searchFilter: {
              placeholder: 'Search for user, e-mail or project',
            },
          },
          keyHistory: {
            noData: {
              heading: 'No Key History to display',
              infoTextEmptyState:
                'Key history will be available as soon as the first project is created.',
            },
            emptyFilters: {
              heading: 'No history items match your filters.',
              infoTextEmptyState: 'Please modify your filter selection.',
            },
            youCaps: 'You',
            youSmall: 'you',
            activityTypes: 'Activity types:',
            search: 'Search for user name or e-mail',
            perspectives: {
              secondPerson: 'have',
              thirdPerson: 'has',
            },
            historyItem_created:
              '<strong>{{userName}}</strong> {{perspective}} <strong>{{activity}}</strong> the project: <strong>{{projectName}}</strong>, key: {{apiKey}}',
            historyItem_deleted:
              '<strong>{{userName}}</strong> {{perspective}} <strong>{{activity}}</strong> the project: <strong>{{projectName}}</strong>, key: {{apiKey}}',
            historyItem_transferred:
              '<strong>{{userName}}</strong> {{perspective}} <strong>{{activity}}</strong> the project: <strong>{{projectName}}</strong>, key: {{apiKey}} from <strong>{{fromUser}}</strong> to <strong>{{toUser}}</strong>',
          },
          keyStatus: {
            active: 'Active',
            cancelled: 'Deleted',
            expired: 'Expired',
          },
          keyDeletion: {
            deleteKeyModal: {
              heading: 'Are you sure you want to delete this key?',
              confirmText: 'Delete key',
              description:
                'Please be aware when you delete this key it can not be retrieved again.',
            },
            savingModal: {
              topMessage: 'Your changes are being saved.',
              bottomMessage: 'Please wait a bit.',
            },
            deletedNotice: {
              message: 'The key has been deleted',
            },
          },
        },
        apiKeyDocumentation: {
          heading: 'Azelis API Documentation',
        },
        table: {
          projectName: 'Project Name',
          scopes: 'Scopes',
          dateCreated: 'Date Created',
          status: 'Status',
          dateExpiry: 'Date Expiry',
          apiKey: 'API Key',
          userName: 'User Name',
          daysLeftTooltip: 'This key will expire in {{daysLeft}} days',
          isExpiredTooltip: 'This key has expired',
        },
      },
      'market-intelligence': {
        heading: 'Market Intelligence',
        marketInsights: {
          tab: 'Market Insights',
          headline: 'Personalized insights for {{name}}',
          addInsight: '+ Add insight',
          empty: 'There are no posts for you yet.',
          regionLabel: 'Country: ',
          regionsLabel: 'Countries: ',
          share: 'Share insight',
          global: 'Global',
          email: {
            intro:
              'Hi, I found this interesting market insight on the Azelis Partner Portal and wanted to share with you:',
            outro: 'Best regards',
            subject: 'I wanted to share this market insight with you',
          },
          filters: {
            market: { label: 'Market:' },
            marketSegment: { label: 'Market Segment:' },
            country: { label: 'Country:' },
            categories: { label: 'Categories:' },
            sort: {
              label_desc: 'Sort by: Newest',
              label_asc: 'Sort by: Oldest',
            },
          },
          categories: [
            'Success Story',
            'Sustainability',
            'Competitors',
            'M&A',
            'Trends',
            'Regulatory',
            'Digitalization',
            'Product Replacement',
          ],
          marketSegments: [
            'Global',
            'CASE',
            'Rubber & PA',
            'Personal Care',
            'Pharma',
            'Food & Health',
            'Animal Nutrition',
            'Specialty Agri Horti',
            'Homecare & Industrial Cleaning',
            'Lubricants & MWF',
            'Essential Chemicals',
            'Electronics',
            'Fine Chemicals',
            'Textiles Leather & Paper',
            'Other Applications',
          ],
        },
        expansionOpportunities: {
          tab: 'Expansion Opportunities',
          empty:
            'The team is working at present to identify new possibilities to expand our business relationship. If there are opportunities you would like to already discuss please contact your Azelis representative for a more detailed discussion.',
          headline: 'Expansion Opportunities',
          addOpportunity: '+ Add opportunity',
          downloadRawData: 'Download raw data',
          principalPortfolio: ['Additives', 'Novecare', 'Coatis', 'ICS PC'],
          portfolio: 'Portfolio',
          marketSegment: 'Market Segment',
          portfolioFit: 'Portfolio fit',
          salesTeamStrength: 'Sales team strength',
          labSupportResources: 'Lab support resources',
          rating: {
            options: ['1', '2', '3', '4', '5'],
          },
        },
        socialListeningReport: {
          tab: 'Social Listening Report',
          currentReport: 'Current Report',
          allReports: 'All Reports',
          noReports: 'There are no older social listening reports.',
          reportDataError:
            'The Social Listening Rpeort data has been incorrectly configured. Please contact your system admin to resolve this.',
          archivesTable: {
            name: 'Name',
            lastModified: 'Last modified',
            owner: 'Owner',
            empty: 'There are no previous social listening reports.',
          },
        },
      },
      'shared-documents': {
        heading: 'Shared Documents',
        tabs: {
          lastAdded: 'LATEST ACTIVITY',
          allDocuments: 'ALL DOCUMENTS',
        },
        errors: {
          api: {
            default:
              'Something went wrong. We are very sorry about the inconvenience, please try again later.',
            accessDenied:
              'It looks like you have not been granted access to this shared folder. Please contact your PDM.',
          },
        },
      },
      account: {
        heading: 'My Account',
        subheadings: {
          generalInformation: 'General Information',
          preferences: 'Preferences for {{principal}}',
        },
        labels: {
          name: 'Name',
          email: 'Email',
          company: 'Partner Company',
          principals: 'Principals',
          memberSince: 'Member since',
          currency: 'Currency',
          weight: 'Weight metric',
        },
      },
      'portal-members': {
        heading: '{{principal}} Portal Members',
        labels: {
          inviteNewUser: 'Create new user',
          unknownName: 'Unknown name',
          unknownEmail: 'Unknown email',
          unknownDate: 'Invitation pending',
          'permission-denied':
            "You don't have the necessary permissions to perform this action. Please contact your system administrator to resolve this issue.",
          table: {
            name: 'Name',
            memberSince: 'Member since',
            userType: 'User type',
            role: 'Role',
            activeMandates: 'Mandates assigned/total',
            activePortfolios: 'Portfolios assigned/total',
          },
          noResults: {
            users: 'No users found',
            principals: 'No principals found',
          },
          userTypes: {
            internal: 'Internal',
            external: 'External',
            global: 'Global',
          },
          roles: {
            azelis_visitor: 'Azelis Visitor',
            distribution_manager: 'Distribution Manager',
            global_user_admin: 'Azelis Admin',
            global_user_edit: 'Global User Edit',
            global_user_view: 'Global User',
            none: 'no-role',
            pdm: 'PDM',
            principal_admin: 'Principal Admin',
            principal_developer: 'Principal Developer',
          },
          search: 'Search by name or email',
          addedNewUser: '{{name}} has been added to {{principal}}',
          user_cannot_be_invited_mailaddress_is_part_of_this_directory:
            '{{name}} needs to be added manually to {{principal}}. Service desk is informed.',
          user_already_exists_for_this_principal:
            'A user with the email address {{email}} already exists for {{principal}}.',
          invalid_email_address:
            'Please enter a correctly formatted email address',
          the_primary_smtp_address_is_an_invalid_value:
            'Please enter a correctly formatted email address. Some special characters, such as a plus (+), may not be allowed.',
          undefined_invitation_error:
            'Something went wrong inviting this user to the Partner Portal. Please check the users details, or contact your system administrator.',
          no_groups_found:
            'No user group was found for this principal. Please check the users details, or contact your system administrator.',
          too_many_groups_found:
            'There was a problem getting user groups for this principal. Please check the users details, or contact your system administrator.',
          id_not_found:
            'There was a problem getting user groups for this principal. Please check the users details, or contact your system administrator.',
          fail_other:
            'There was an unknown error creating this user. Please check the users details, or contact your system administrator.',
          updateMandateFilters: 'Mandate filter updated',
          userSelected: 'user selected',
          usersSelected: 'users selected',
        },
      },
      'member-details': {
        generalUserInformation: 'General user information',
        userMandates: 'Mandates',
        userPreferences: 'Preferences',
        labels: {
          email: 'Email',
          memberSince: 'Member Since',
          userType: 'User type',
          role: 'Role',
          activeMandates: 'Active mandates',
          editedRole:
            "{{name}}'s role has been change to {{newRole}} for {{principal}}",
          currency: 'Currency',
          weight: 'Weight',
        },
        buttons: {
          editUser: 'Edit user',
          deleteUser: 'Delete user',
        },
      },
      'global-users': {
        heading: 'Overview global users',
        noGlobalUsers: 'You have not set up any global users yet',
        addGlobalUser: 'Add new global user',
        editGlobalUser: 'Edit principal access rights',
        createGlobalUsers: {
          name: 'Name',
          editRights: 'Edit rights',
          editRightsAll: 'Give edit rights to all*',
          step1Text: '1. Add new global user',
          step1Description:
            'A global user automatically has view rights for all principals, as well as access to current and future mandates. As an admin, you can manually give global users edit rights per principal, so they can maintain the users on the portal, as well as add market insights.',
          step2Text: '{{showEditNumber}} Edit access rights for {{user}}',
          step2Description:
            'A global user automatically has view rights for all principals, as well as access to current and future mandates. As an admin, you can manually give global users edit rights per principal, so they can maintain the users on the portal, as well as add market insights.',
          step2Search: 'Search for principal name',
          principalSelectionMessage:
            '*For all future principals, that will be added to the portal, the global user will have “view rights” per default.',
          numberOfUsers_one: '{{count}} user',
          numberOfUsers_other: '{{count}} users',
          numberOfPrincipals_one: '{{count}} Principals',
          numberOfPrincipals_other: '{{count}} Principals',
          chooseUser: 'Choose user',
          finish: 'Finish',
        },
        removeGlobalUser: {
          removeRightsHeader:
            'Do you really want to remove global user rights?',
          removeRightsMessage:
            'You are about to remove the global user rights for {{userName}}. If you confirm, this user will get back their initial rights and permissions before they were nominated to global users.',
          confirm: 'Remove user',
        },
      },
      signIn: {
        errors: {
          'no-valid-ad-groups':
            'It seems you are not yet approved to use the Azelis Partner Portal.',
          'no-valid-token-cert-found': 'We could not verify your Identity.',
          'no-valid-token':
            'Your authentication via Microsoft ActiveDirectory was not successful.',
          'ad_no-role':
            'You are currently not assigned a role for the portal. Please contact your PDM to resolve this issue.',
          ad_unknown: 'An unknown error occured while trying to log you in.',
        },
        loginWithActiveDirectory: 'Login',
        logout: 'Logout',
      },
      'missing-portal-member': {
        heading: 'Something is missing',
        description:
          "Your account hasn't been created in the Partner Portal yet.\nPlease contact your PDM to resolve this.",
      },
      'auth-error': {
        heading: 'Something went wrong',
        description:
          'We are unable to process your login request at this time.\nPlease try again.\n\nIf the issue persists, please contact your PDM for further assistance.',
        buttonText: 'Return to login screen',
      },
      operationalPerformance: {
        heading: 'Performance',
        filters: {
          country: 'Country: ',
          portfolio: 'Portfolio: ',
          marketSegment: 'Market Segment: ',
        },
        mandatesNote:
          '*Please note in a small number of cases sales and volumes may be reflected in a Market Segment other than the intended market the product will be used in. This is due to Azelis’ classification of the customer by their primary market. Wherever possible Azelis will identify and add additional customer markets to correct the data. Please reach out to your Principal Development Manager if you need any further clarification.',
      },
    },
    languages: {
      en_US: 'English (US)',
      en_GB: 'English (UK)',
      fr_FR: 'French',
      de_DE: 'German',
      zh_CN: 'Chinese',
      es_ES: 'Spanish',
    },
    emails: {
      inviteUser: {
        subject:
          'You have been invited to the Azelis Partner Portal for {{selectedPrincipalName}}',
        heading:
          'You have been invited to the Azelis Partner Portal for {{selectedPrincipalName}}',
        line1: 'Dear {{userName}}',
        line2:
          'You were invited to the Azelis Partner Portal for {{selectedPrincipalName}} by {{invitedByName}} ({{invitedByEmail}}).',
        line3:
          'Please follow {{- invitationLink}} in order to log in to your new account and start exploring the Portal. Please be aware that it can take up to 24 hours for full portal functionality to be enabled.',
        line3LinkText: 'this link',
        theAzelisTeam: 'The Azelis team',
        copyright: 'Copyright © Azelis {{year}}. All rights reserved.',
      },
      onboardingErrorEmail: {
        subject: 'User onboarding error for adding a new user to {{source}}',
        greeting: 'Hello Team,',
        line1: 'An error occurred in the user onboarding process.',
        line2: 'Details:',
        principal: 'Principal Name: {{principal}}',
        email: 'User Email: {{email}}',
        system: 'System where the error occurs: {{system}}',
        errorCode: 'Error Code: {{errorCode}}',
        signOff: 'Kind regards,',
      },
      generalErrorEmail: {
        subject: 'General error occured on {{source}}',
        greeting: 'Hello Team,',
        line1: 'A general error occurred.',
        line2: 'Details:',
        user: 'User: {{user}}',
        source: 'Page/Component where the error occurs: {{source}}',
        errorCode: 'Error Code: {{errorCode}}',
        signOff: 'Kind regards,',
      },
      requestServiceDeskSetup: {
        subject: 'Request: Add user to Principal Portal groups',
        greeting: 'Dear Azelis Service Desk Team,',
        line1:
          '{{userName}} ({{email}}) was invited to the Partner Portal for {{principalName}} by {{creatorName}} ({{creatorEmail}}).',
        line2:
          'Please add {{email}} to the AD group "SG_Principal_ALL_USERS" group (if they are not already part of the group) and "SG_{{principalId}}" group.',
        line3: 'In case of questions, please contact (?).',
        signOff: 'Best regards,',
        team: 'The Partner Portal Team',
      },
      ediContact: {
        mailTable: {
          ediProcessSelection: 'Interested EDI processes',
          countriesSelection: 'Countries',
          technicalTeamSelection: 'Involved technical team',
          additionalInfo: 'Additional Information',
        },
        azelis: {
          subject: 'New briefing request',
          greeting: 'Hello,',
          line1:
            'A new briefing to set up an EDI process was posted by {{creatorName}} ({{principalName}}) on the Principal Portal.',
          line2: 'Summary of the request:',
          signOff: 'Your Partner Portal Team',
        },
        principal: {
          subject: 'Your EDI contact request has been received',
          greeting: 'Dear {{name}}',
          line1:
            'Thank you for getting in contact with us to set up the EDI process. We will get back to you shortly to schedule the initial call.',
          line2:
            'Below, you can find a summary of the information that has been provided to us.',
          signOff: 'The Azelis Team',
        },
      },
      apiKeyTransfer: {
        subject: 'API Key Transfer',
        greeting: 'Hello {{transferredTo}}',
        line1_single:
          'An API key has been transferred to you by {{transferredBy}} in the Partner Portal.',
        line1_multi:
          '{{apiKeysCount}} API keys have been transferred to you by {{transferredBy}} in the Partner Portal.',
        line2_single:
          'As always, you can find the transferred API key in your Partner Portal account.',
        line2_multi:
          'You can find the transferred API keys as always in your Partner Portal account.',
        transferredApiKey_single: 'Summary of the transferred API key',
        transferredApiKey_multi: 'Transferred API Key',
        tableHeaders: {
          projectName: 'Project name',
          transferredFrom: 'Previous key owner',
          scopes: 'Scopes',
          expiryDate: 'Date expiry',
        },
        unknownUser: 'Unknown user',
        noScopes: 'No active scopes',
      },
    },
  },
};
