import { Checkbox } from 'components/Checkbox';
import { Input } from 'components/Input';
import { LoadingBounce } from 'components/Loading';
import { Scope } from 'hooks/queries/useScopes';
import React from 'react';
import { useTranslation } from 'react-i18next';

type APIGeneratorPageContentProps = {
  availableScopes: Scope[];
  isApiLoading: boolean;
  onScopesChange: (selectedScope: string) => void;
  handleProjectNameChange: (projectName: string) => void;
};

export const APIGeneratorPageContent: React.FC<
  APIGeneratorPageContentProps
> = ({
  availableScopes,
  isApiLoading,
  onScopesChange,
  handleProjectNameChange,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {isApiLoading && <LoadingBounce />}
      {!isApiLoading && (
        <form>
          <div className="text-grey-700">
            <p className="mt-4" data-testid="description">
              {t(
                'features:data-connection:apiKeyGenerator:apiModal:step1:description',
              )}
            </p>
            <div className="mt-4 w-full">
              <label htmlFor="project-name" className="mb-1 block font-medium">
                {t(
                  'features:data-connection:apiKeyGenerator:apiModal:step1:chooseApi',
                )}
              </label>

              {availableScopes.map(({ name }) => (
                <div className="mt-1" key={name}>
                  <Checkbox
                    label={name}
                    onChange={() => onScopesChange(name)}
                  />
                </div>
              ))}
            </div>
            <p className="mt-4">
              {t(
                'features:data-connection:apiKeyGenerator:apiModal:step1:readOnlyNotice',
              )}
            </p>
            <div className="mt-5 w-full">
              <label htmlFor="project-name" className="mb-1 block font-medium">
                {t(
                  'features:data-connection:apiKeyGenerator:apiModal:step1:projectInputHeader',
                )}
              </label>
              <div>
                <Input
                  id="project-name"
                  placeholder={t(
                    'features:data-connection:apiKeyGenerator:apiModal:step1:projectInputPlaceholder',
                  )}
                  onChange={(e) => handleProjectNameChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
