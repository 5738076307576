import classNames from 'classnames';
import { Button, ButtonType } from 'components/Button';
import { Icon, IconName } from 'components/Icon';
import { FC, ReactElement } from 'react';

export type AlertFlavour =
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'infoNoBg'
  | 'promo'
  | 'description';

export type Sizes = 'small' | 'large';

export type AlertProps = {
  message: ReactElement | string;
  flavour: AlertFlavour;
  icon?: IconName;
  iconSize?: Sizes;
  iconColor?: string;
  iconTop?: boolean;
  hasBorder?: boolean;
  buttonLabel?: string;
  buttonLabelFontSize?: string;
  buttonSize?: Sizes;
  onClick?: () => void;
  headline?: string;
  onCancel?: () => void;
};

export const FlavourToColorMapping: { [key in AlertFlavour]: string } = {
  success: 'bg-green-50 text-green-300 p-2',
  error: 'bg-red-50 text-red-200 p-2',
  warning: 'bg-orange-50 text-orange-300 p-2',
  info: 'bg-blue-50 text-blue-400 p-2',
  infoNoBg: 'text-blue-400 p-2',
  promo: 'bg-blue-50 text-blue-400 p-2',
  description: 'bg-blue-50 text-grey-700 p-4',
};

export const IconSizeMapping: { [key in Sizes]: string } = {
  small: 'w-20px h-20px',
  large: 'w-48px h-48px',
};

export const Alert: FC<AlertProps> = ({
  message,
  flavour,
  icon,
  iconSize = 'small',
  iconColor = 'blue-400',
  iconTop,
  hasBorder = true,
  buttonLabel,
  buttonLabelFontSize,
  buttonSize = 'large',
  onClick,
  headline,
  onCancel,
}) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-col rounded text-xs md:flex-row',
        FlavourToColorMapping[flavour],
        { border: hasBorder },
        { 'relative md:pr-7': onCancel },
      )}
    >
      {icon && (
        <div
          data-testid="icon"
          className={classNames(`shrink-0`, IconSizeMapping[iconSize], {
            'mt-2px items-start': headline,
            'mt-2px': iconTop,
            'my-auto': !headline,
            'mr-1': iconSize === 'small',
            'mr-2': iconSize === 'large',
          })}
        >
          <Icon name={icon} color={iconColor} />
        </div>
      )}
      <div className="flex flex-col">
        {headline && <div className="font-bold">{headline}</div>}
        <div className="break-words">{message}</div>
      </div>
      {buttonLabel && (
        <div
          className={classNames('flex shrink-0 md:ml-3', {
            'items-center justify-end md:ml-4': flavour === 'promo',
          })}
        >
          <Button
            className={classNames('max-w-[296px] shrink-0 whitespace-nowrap', {
              'py-4px h-5 px-3': buttonSize === 'small',
              'h-7 px-7 py-2': buttonSize === 'large',
            })}
            buttonType={ButtonType.ICON}
            labelFontSize={buttonLabelFontSize}
            label={buttonLabel}
            onClick={onClick}
          />
        </div>
      )}
      {onCancel && (
        <button
          data-testid="modal-cancel"
          className="text-grey-600 right-16px top-16px absolute h-3 w-3 shrink-0 items-start hover:text-blue-100 focus:text-blue-100"
          onClick={onCancel}
        >
          <Icon name="Close" color="grey-700" />
        </button>
      )}
    </div>
  );
};
