import { Checkbox } from 'components/Checkbox';
import { Icon } from 'components/Icon';
import { Notification } from 'lib/notifications/notifications';
import React from 'react';
import { useTranslation } from 'react-i18next';

type APIKeyDisplayContentProps = {
  handleAPICheckboxChange: (isChecked: boolean) => void;
  newAPIKey: string;
};

export const APIKeyDisplayContent: React.FC<APIKeyDisplayContentProps> = ({
  handleAPICheckboxChange,
  newAPIKey,
}) => {
  const { t } = useTranslation();

  const copyApiToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(newAPIKey);
      Notification({
        message: t(
          'features:data-connection:apiKeyGenerator:apiModal:step2:copiedNotification',
        ),
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <div className="text-grey-700">
      <div className="flex">
        <p className="mt-1" data-testid="description">
          {t(
            'features:data-connection:apiKeyGenerator:apiModal:step2:securityWarning:part1',
          )}
        </p>

        <p className="ml-1 mt-1 font-semibold" data-testid="description">
          {t(
            'features:data-connection:apiKeyGenerator:apiModal:step2:securityWarning:part2',
          )}
        </p>
      </div>
      <div className="flex">
        <p className="mt-1" data-testid="description">
          {t(
            'features:data-connection:apiKeyGenerator:apiModal:step2:copyNotice',
          )}
        </p>
      </div>

      <div className="mt-5 w-full">
        <label htmlFor="api-key-display" className="mb-1 block font-medium">
          {t(
            'features:data-connection:apiKeyGenerator:apiModal:step2:inputHeader',
          )}
        </label>

        <div className="mt-1 flex justify-between border p-2">
          <p data-testid="apiKey">{newAPIKey}</p>
          <div className="h-4 cursor-pointer" onClick={copyApiToClipboard}>
            <Icon name="Copy" />
          </div>
        </div>
        <div className="my-1 mt-5">
          <Checkbox
            label={t(
              'features:data-connection:apiKeyGenerator:apiModal:step2:APICopied',
            )}
            onChange={(val) => handleAPICheckboxChange(val)}
          />
        </div>
      </div>
    </div>
  );
};
