import { Heading } from 'components/Heading';
import { LoadingBounce } from 'components/Loading';
import { useApiKeys } from 'hooks/queries';
import { useTranslation } from 'react-i18next';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const APIDocumentation = () => {
  const { apiSpecification, isApiSpecificationLoading } = useApiKeys();
  const { t } = useTranslation();
  return (
    <div className="max-w-xl-content xl:min-w-content mx-auto mb-7 space-y-5 px-4 pt-6 md:mb-9 md:px-6 md:pt-7">
      <Heading
        text={t(`features:data-connection:apiKeyDocumentation:heading`)}
        margin={0}
        level="h1"
      />
      <div className="-m-2">
        {isApiSpecificationLoading && <LoadingBounce />}
        <SwaggerUI url={apiSpecification?.properties.value.link} />
      </div>
    </div>
  );
};
