import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  APIKeyData,
  APIKeyLastAction,
  APIKeyStatus,
} from 'features/DataConnection/types';
import { User, signInWithCustomToken } from 'firebase/auth';
import { useCallback, useMemo } from 'react';
import { Role } from 'shared/types/authorization';
import { auth } from 'utils/firebase';
import {
  createApiKeySubscription,
  deleteApiKeySubscription,
  getApiKeyHistory,
  getApiKeySpecification,
  getApiKeySubscriptions,
  getFirebaseTokenForDataConnectionAPI,
  moveApiKeySubscription,
} from 'utils/firebase/cloud-functions';
import { useCurrentPrincipal } from './useCurrentPrincipal';
import { useCurrentUser } from './useCurrentUser';
import { useUsers } from './useUsers';

type UserWithToken = User & { accessToken: string };

export type APIScope = {
  active: boolean;
  createdAt: string;
  description: string;
  id: string;
  name: string;
  updatedAt: string;
};

export type APISpecsResponseData = {
  id: string;
  type: string;
  name: string;
  properties: {
    format: string;
    value: {
      link: string;
    };
  };
};

export type InitialAPIData = {
  createdDate: string;
  displayName: string;
  endDate?: string;
  expirationDate: string;
  id: string;
  name: string;
  notificationDate?: string;
  primaryKey: string;
  principalId: string;
  scopes: APIScope[];
  startDate: string;
  state: APIKeyStatus;
  stateComment?: string;
  lastAction: APIKeyLastAction;
  user: {
    email: string;
    firebaseId: string;
    guid: string;
  };
};

export type InitialAPIHistoryData = {
  createdDate: string;
  displayName?: string;
  id: string;
  name: string;
  primaryKey: string;
  scopes: APIScope[];
  type: string;
  user: {
    email: string;
    firebaseId: string;
    guid: string;
  };
  fromUser?: {
    email: string;
    firebaseId: string;
    guid: string;
  };
  toUser?: {
    email: string;
    firebaseId: string;
    guid: string;
  };
  invitedBy?: {
    email: string;
    firebaseId: string;
    guid: string;
  };
};

export type APIHistory = {
  activity: string;
  apiKey: string;
  dateOfActivity: string;
  keyId: string;
  projectName: string;
  scopes: APIScope[];
  userName?: string;
  fromUser?: string;
  toUser?: string;
  invitedby?: string;
};

export type ApiUser = {
  guid: string;
  firebaseId: string;
  email: string;
};

export type APIsHookResult = {
  creatorId: string;
  projectName: string;
};

export type UseAPIKeyResult = {
  apiKeys: APIKeyData[] | undefined;
  areAvailableKeysLoading: boolean;
  apiHistory?: APIHistory[];
  isApiKeyHistoryLoading: boolean;
  apiSpecification: APISpecsResponseData | undefined;
  isApiSpecificationLoading: boolean;
  createApiRecord: (
    partial: Partial<APIKeyData>,
  ) => Promise<InitialAPIHistoryData>;
  deleteApiRecord: (selectedApiId: string) => Promise<void>;
  transferOwnershipOfAPIRecord: (
    selectedApiId: string,
    moveToUser: ApiUser,
    moveFromUserGuid: string,
  ) => Promise<void>;
};

// 🍻 Code Bounty 🍻
// Find a way to move this logic to server-side.
// Tokens for Data Connection endpoints must be generated
// by the signInWithCustomToken() function, and I can't find
// a server-side equivallent.
export const getFirebaseData = async (currentPrincipal: string) => {
  const {
    data: { token, adUserId },
  } = await getFirebaseTokenForDataConnectionAPI({
    currentPrincipal: currentPrincipal,
  });

  if (!token) return;

  const { user } = await signInWithCustomToken(auth, token);

  return { bearerToken: (user as UserWithToken).accessToken, adUserId };
};
///

export const useApiKeys = (): UseAPIKeyResult => {
  const queryClient = useQueryClient();
  const { user: currentUser, role } = useCurrentUser();
  const { users } = useUsers({
    disabled: role === Role.PRINCIPAL_DEVELOPER,
  });
  const { currentPrincipal } = useCurrentPrincipal(currentUser.id);

  const firebaseData = useMemo(
    async () => await getFirebaseData(currentPrincipal.id),
    [currentPrincipal.id],
  );

  const { data: availableKeys, isLoading: areAvailableKeysLoading } = useQuery({
    queryFn: async () =>
      await getApiKeySubscriptions({
        principalId: currentPrincipal.id,
        userEmail: currentUser.email,
        role,
        bearerToken: (await firebaseData)?.bearerToken,
      }),
    queryKey: [
      'subscriptions',
      currentPrincipal.principalPri,
      users?.map(({ user }) => user.id),
    ],
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
  });

  const { data: apiHistory, isLoading: isApiKeyHistoryLoading } = useQuery({
    queryFn: async () =>
      await getApiKeyHistory({
        principalId: currentPrincipal.id,
        userEmail: currentUser.email,
        userRole:
          role === 'principal_developer'
            ? 'PrincipalDeveloper'
            : 'PrincipalAdmin',
        bearerToken: (await firebaseData)?.bearerToken,
        availableKeys,
      }),
    queryKey: ['history', currentPrincipal.principalPri, users, availableKeys],
    select: ({ data }) => (Array.isArray(data) ? data : undefined),
    refetchOnWindowFocus: false,
  });

  const createApiRecord = useCallback(
    async (newApi: Partial<APIKeyData>): Promise<InitialAPIHistoryData> => {
      const response = await createApiKeySubscription({
        principalId: currentPrincipal.principalPri,
        userEmail: currentUser.email,
        projectName: newApi.projectName,
        scopes: newApi.scopes,
        userGuid: (await firebaseData)?.adUserId,
        bearerToken: (await firebaseData)?.bearerToken,
      });

      queryClient.invalidateQueries({
        queryKey: ['subscriptions'],
      });

      return response.data;
    },
    [
      currentPrincipal.principalPri,
      currentUser.email,
      firebaseData,
      queryClient,
    ],
  );

  const deleteApiRecord = useCallback(
    async (selectedApiId: string): Promise<void> => {
      await deleteApiKeySubscription({
        selectedApiId,
        bearerToken: (await firebaseData)?.bearerToken,
      });

      queryClient.invalidateQueries({
        queryKey: ['subscriptions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['history'],
      });
    },
    [firebaseData, queryClient],
  );

  const transferOwnershipOfAPIRecord = useCallback(
    async (
      selectedApiId: string,
      moveToUser: ApiUser,
      moveFromUserGuid: string,
    ): Promise<void> => {
      const response = await moveApiKeySubscription({
        selectedApiId,
        moveFromUserGuid,
        moveToUser,
        bearerToken: (await firebaseData)?.bearerToken,
      });

      if (response) {
        queryClient.invalidateQueries({
          queryKey: ['subscriptions', 'history'],
        });
      }
    },
    [firebaseData, queryClient],
  );

  const { data: apiSpecification, isLoading: isApiSpecificationLoading } =
    useQuery({
      queryFn: async () =>
        await getApiKeySpecification({
          bearerToken: (await firebaseData)?.bearerToken,
        }),
      queryKey: ['apiSpecification'],
      select: ({ data }) => data,
      refetchOnWindowFocus: false,
    });

  const apiData = useMemo(() => {
    return {
      availableKeys,
      areAvailableKeysLoading,
      apiSpecification,
      isApiSpecificationLoading,
      apiHistory,
      isApiKeyHistoryLoading,
    };
  }, [
    apiHistory,
    apiSpecification,
    areAvailableKeysLoading,
    availableKeys,
    isApiKeyHistoryLoading,
    isApiSpecificationLoading,
  ]);

  return {
    areAvailableKeysLoading: apiData.areAvailableKeysLoading,
    apiKeys: apiData.availableKeys,
    apiSpecification: apiData.apiSpecification,
    isApiSpecificationLoading: apiData.isApiSpecificationLoading,
    apiHistory: apiData.apiHistory,
    isApiKeyHistoryLoading: apiData.isApiKeyHistoryLoading,
    createApiRecord,
    deleteApiRecord,
    transferOwnershipOfAPIRecord,
  };
};
