import { routes } from 'controllers/ContentController/Routes';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import {
  ApiKeyTransferEmailItem,
  BaseUserType,
  EmailRequest,
  EmailType,
  MailGenerationParameter,
} from 'utils/firebase';
import { Principal } from 'utils/firebase/collection';

export type ContactFormData = {
  ediProcess: {
    selected: string[];
    other?: string;
  };
  countries: string;
  technicalTeams: BaseUserType[];
  additionalInformation: string;
};

//generates html for value columns of table
const generateValueField = (value: string | string[] | BaseUserType[]) => {
  if (typeof value === 'string') {
    return `<div>${value}</div>`;
  }

  //create ul for value if value is a list of items
  if (typeof value === 'object' && value.length) {
    return `<ul style="margin: 0; padding: 0">
       ${value
         .map((val, index) => {
           let listItem = '';
           if (index === value.length - 1) {
             listItem += `<li style="margin: 0; list-style: none">`;
           } else {
             listItem += `<li style="margin: 0 0 10px 0; list-style: none">`;
           }

           if (typeof val === 'string') {
             listItem += val;
           }

           if (typeof val === 'object') {
             listItem += `
            <div>${val.firstName} ${val.lastName}</div>
            <a href="mailto:${val.email}">${val.email}</a>
          `;
           }
           return (listItem += `</li>`);
         })
         .join('---')}
    </ul>`;
  }
};

//create table content for ediContact mail
const generateTableContent = (
  t: TFunction<'translation', undefined, 'translation'>,
  ediContactFormData: ContactFormData,
) => {
  const { ediProcess, countries, technicalTeams, additionalInformation } =
    ediContactFormData;

  const other = ediProcess.other
    ? `${t('modals:contactFormModal:ediProcessSelection:other')}: ${
        ediProcess.other
      }`
    : null;

  return (
    [
      [
        t('emails:ediContact:mailTable:ediProcessSelection'),
        compact([
          ...ediProcess.selected.filter(
            (elem) =>
              elem !== t(`modals:contactFormModal:ediProcessSelection:other`),
          ),
          other,
        ]),
      ],
      [t('emails:ediContact:mailTable:countriesSelection'), countries],
      [t('emails:ediContact:mailTable:technicalTeamSelection'), technicalTeams],
      [t('emails:ediContact:mailTable:additionalInfo'), additionalInformation],
    ]
      //iterate through rows of the table and add title (left column for each row) and values (right column for each row)
      .map(
        ([title, value]) => `<tr>
          <th style="
              text-align: left;
              vertical-align: top;
              padding-right: 16px;
              padding-bottom: 10px;
              padding-top: 10px;
              width: 35%;
              border-bottom: 1px solid #d9dbdd;
              border-top: 1px solid #d9dbdd;
            ">
            ${title}
          </th>
          <td style="
              vertical-align: top;
              padding-bottom: 10px;
              padding-top: 10px;
              border-bottom: 1px solid #d9dbdd;
              border-top: 1px solid #d9dbdd;
            ">
            ${generateValueField(value)}
          </td>
        </tr>`,
      )
      .join('')
  );
};

const constructTableData = (
  t: TFunction<'translation', undefined, 'translation'>,
  ediContactFormData: ContactFormData,
): string => {
  return `<table cellpadding="0" cellspacing="0" border="0" width="100%">
            ${generateTableContent(t, ediContactFormData)}
          </table>`;
};

const constructTransferEmailLink = (
  keyItems: ApiKeyTransferEmailItem[],
  currentPrincipal: Principal,
  transferredBy: string,
) => {
  const itemsAsUrlStrings = encodeURIComponent(
    JSON.stringify({ transferredBy, keyItems }),
  );
  return `${window.location.origin}${
    routes(currentPrincipal.slug).partnerAreaRoute.url
  }#${itemsAsUrlStrings}`;
};

//Generates mail content for different `emailTypes`
export const generateMailParameter = ({
  t,
  emailType,
  data,
}: MailGenerationParameter): EmailRequest => {
  switch (emailType) {
    case EmailType.INVITE:
      return {
        emailType,
        //TODO: Refactor: email shouldn't be optional (see ticket AZEPP-982)
        sendTo: data.newUser.email ?? '',
        content: {
          subject: t('emails:inviteUser:subject', {
            selectedPrincipalName: data.currentPrincipal.name,
          }),
          heading: t('emails:inviteUser:heading', {
            selectedPrincipalName: data.currentPrincipal.name,
          }),
          line1: t('emails:inviteUser:line1', {
            userName: `${data.newUser.firstName} ${data.newUser.lastName}`,
          }),
          line2: t('emails:inviteUser:line2', {
            selectedPrincipalName: data.currentPrincipal.name,
            invitedByName: data.currentUser.name,
            invitedByEmail: data.currentUser.email,
          }),
          line3: t('emails:inviteUser:line3', {
            invitationLink: `<a target="_blank" href="${
              data.inviteUrl ?? 'https://partner.azelis.com'
            }">${t('emails:inviteUser:line3LinkText')}</a>`,
          }),
          thankYou: t('emails:inviteUser:thankYou'),
          theAzelisTeam: t('emails:inviteUser:theAzelisTeam'),
          copyright: t('emails:inviteUser:copyright', {
            year: new Date().getFullYear(),
          }),
        },
      };
    case EmailType.USER_ONBOARDING_ERROR:
      return {
        emailType,
        content: {
          subject: t('emails:onboardingErrorEmail:subject', {
            source: data.errorSource,
          }),
          greeting: t('emails:onboardingErrorEmail:greeting'),
          line1: t('emails:onboardingErrorEmail:line1'),
          line2: t('emails:onboardingErrorEmail:line2'),
          principal: t('emails:onboardingErrorEmail:principal', {
            principal: data.currentPrincipal.name,
          }),
          email: t('emails:onboardingErrorEmail:email', {
            email: data.newUser.email,
          }),
          system: t('emails:onboardingErrorEmail:system', {
            system: data.errorSource,
          }),
          errorCode: t('emails:onboardingErrorEmail:errorCode', {
            errorCode: data.errorMessage,
          }),
          signOff: t('emails:onboardingErrorEmail:signOff'),
          theAzelisTeam: `${data.currentUser.name} (${data.currentUser.email})`,
        },
      };
    case EmailType.ERROR:
      return {
        emailType,
        content: {
          subject: t('emails:generalErrorEmail:subject', {
            source: data.errorSource,
          }),
          greeting: t('emails:generalErrorEmail:greeting'),
          line1: t('emails:generalErrorEmail:line1'),
          line2: t('emails:generalErrorEmail:line2'),
          user: t('emails:generalErrorEmail:user', {
            user: `${data.currentUser.name} (${data.currentUser.email})`,
          }),
          source: t('emails:generalErrorEmail:source', {
            source: data.errorSource,
          }),
          errorCode: t('emails:generalErrorEmail:errorCode', {
            errorCode: data.errorMessage,
          }),
          signOff: t('emails:generalErrorEmail:signOff'),
          theAzelisTeam: `${data.currentUser.name} (${data.currentUser.email})`,
        },
      };

    case EmailType.SERVICE_DESK:
      return {
        emailType,
        content: {
          subject: t('emails:requestServiceDeskSetup:subject'),
          greeting: t('emails:requestServiceDeskSetup:greeting'),
          line1: t('emails:requestServiceDeskSetup:line1', {
            userName: `${data.newUser.firstName} ${data.newUser.lastName}`,
            email: data.newUser.email,
            principalName: data.currentPrincipal.name,
            creatorName: data.currentUser.name,
            creatorEmail: data.currentUser.email,
          }),
          line2: t('emails:requestServiceDeskSetup:line2', {
            email: data.newUser.email,
            principalId: data.currentPrincipal.id,
          }),
          line3: t('emails:requestServiceDeskSetup:line3'),
          signOff: t('emails:requestServiceDeskSetup:signOff'),
          theAzelisTeam: `${data.currentUser.name} (${data.currentUser.email})`,
          copyright: t('emails:inviteUser:copyright', {
            year: new Date().getFullYear(),
          }),
        },
      };
    case EmailType.EDI_CONTACT: {
      const tableData = constructTableData(t, data.ediContactFormData);
      return {
        emailType,
        //TODO: Refactor: email shouldn't be optional (see ticket AZEPP-982)
        sendTo: data.currentUser.email ?? '',
        content: {
          azelis: {
            subject: t('emails:ediContact:azelis:subject'),
            greeting: t('emails:ediContact:azelis:greeting'),
            line1: t('emails:ediContact:azelis:line1', {
              creatorName: data.currentUser.name,
              principalName: data.currentPrincipal.name,
            }),
            line2: t('emails:ediContact:azelis:line2'),
            tableData,
            signOff: t('emails:ediContact:azelis:signOff'),
          },
          principal: {
            subject: t('emails:ediContact:azelis:subject'),
            greeting: t('emails:ediContact:principal:greeting', {
              name: data.currentUser.name,
            }),
            line1: t('emails:ediContact:principal:line1'),
            line2: t('emails:ediContact:principal:line2'),
            tableData,
            signOff: t('emails:ediContact:principal:signOff'),
          },
        },
      };
    }
    case EmailType.API_KEY_TRANSFER: {
      const singleOrMulti =
        data.keysTransferData.length > 1 ? 'multi' : 'single';
      return {
        emailType,
        sendTo: data.sendTo,
        content: {
          subject: t('emails:apiKeyTransfer:subject'),
          greeting: t('emails:apiKeyTransfer:greeting', {
            transferredTo: data.transferredTo,
          }),
          line1: t(`emails:apiKeyTransfer:line1_${singleOrMulti}`, {
            transferredBy: data.transferredBy,
            apiKeysCount: data.keysTransferData.length,
          }),
          transferredApiKey: t(
            `emails:apiKeyTransfer:transferredApiKey_${singleOrMulti}`,
          ),
          tableHeaders: {
            projectName: t('emails:apiKeyTransfer:tableHeaders:projectName'),
            transferredFrom: t(
              'emails:apiKeyTransfer:tableHeaders:transferredFrom',
            ),
            scopes: t('emails:apiKeyTransfer:tableHeaders:scopes'),
            expiryDate: t('emails:apiKeyTransfer:tableHeaders:expiryDate'),
          },
          tableData: data.keysTransferData,
          line2: t(`emails:apiKeyTransfer:line2_${singleOrMulti}`),
          link: constructTransferEmailLink(
            data.keysTransferData,
            data.currentPrincipal,
            data.transferredBy,
          ),
        },
      };
    }
  }
};
