export enum APIKeyTableColumn {
  USERNAME = 'userName',
  API_KEY = 'apiKey',
  DATE_CREATED = 'dateCreated',
  DATE_EXPIRY = 'dateExpiry',
  PROJECT_NAME = 'projectName',
  SCOPES = 'scopes',
  STATUS = 'status',
}

export enum APIKeyLastAction {
  CREATED = 'Created',
  MOVED = 'Moved',
}

export enum APIKeyStatus {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export type APIKeyData = {
  keyId: string;
  apiKey: string;
  dateCreated: string;
  dateExpiry: string;
  projectName: string;
  lastAction: APIKeyLastAction;
  scopes: string[];
  status: APIKeyStatus;
  userDetails: {
    email: string;
    firebaseId: string;
    guid: string;
    name?: string;
  };
};
