export enum Permission {
  CUSTOMER_PORTAL_INSIGHTS_VIEW = 'customer_portal_insights_view',
  DASHBOARD_VIEW = 'dashboard_view',
  EDIT_GLOBAL_USERS = 'edit_global_users',
  EXPANSION_OPPORTUNITIES_VIEW = 'expansion_opportunities_view',
  MARKET_INSIGHTS_EDIT = 'market_insights_edit',
  MARKET_INSIGHTS_VIEW = 'market_insights_view',
  OPERATIONAL_PERFORMANCE_VIEW = 'operational_performance_view',
  SALES_REPORTING_VIEW = 'sales_reporting_view',
  SHARED_DOCUMENTS_VIEW = 'shared_documents_view',
  SUPPLIER_PAGES_VIEW = 'supplier_pages_view',
  USER_ROLE_MGMT_EDIT = 'user_role_mgmt_edit',
  USER_ROLE_MGMT_VIEW = 'user_role_mgmt_view',
  DATA_CONNECTION_DEVELOPER_SCREENS = 'data_connection_developer_screens',
  DATA_CONNECTION_DELETE_KEYS = 'data_connection_delete_keys',
  DATA_CONNECTION_API_MANAGEMENT = 'data_connection_api_management',
}

export type Permissions = { [key in Permission]?: boolean };
