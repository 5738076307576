import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';

type ApiKeyManagementFiltersNoResultsProps = {
  module: 'apiKeyGenerator' | 'apiKeyManagement';
  tab: 'keyHistory' | 'keyOverview';
};

export const ApiKeyManagementFiltersNoResults: React.FC<
  ApiKeyManagementFiltersNoResultsProps
> = ({ tab, module }) => {
  const { t } = useTranslation();
  return (
    <div className="border-grey-300 flex flex-col items-center space-y-4 rounded border p-7">
      <span className="border-grey-300 h-20 w-20">
        <Icon name="EmptyMarket" />
      </span>
      <Heading
        text={t(
          `features:data-connection:${module}:${tab}:emptyFilters:heading`,
        )}
        margin={0}
        level="h3"
      />
      <p className="text-grey-600 max-w-622px mt-4 text-center">
        {t(
          `features:data-connection:${module}:${tab}:emptyFilters:infoTextEmptyState`,
        )}
      </p>
    </div>
  );
};
