import { Badge } from 'components/Badge';
import { DropdownFilter } from 'components/Dropdown/DropdownVariants/DropdownFilter';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { Link } from 'components/Link';
import { Loading } from 'components/Loading';
import { PermissionDenied } from 'components/PermissionDenied/PermissionDenied';
import {
  AddUserModal,
  SavingModal,
  SelectUserTypeModal,
} from 'components/UserManagementModals';
import { NewUserDetails } from 'components/UserManagementModals/AddUser/AddUserModal';
import { SelectInternalUser } from 'components/UserManagementModals/SelectInternalUser/SelectInternalUser';
import { DeleteUsersModal } from 'features/DeleteUsersModal/DeleteUsersModal';
import {
  BaseUseUserResult,
  useCRMMandates,
  useCurrentPrincipal,
  useCurrentUser,
  useRoleDefinitions,
  useUsers,
} from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import { TFunction } from 'i18next';
import { generateMailParameter } from 'lib/mailGeneration';
import { Notification } from 'lib/notifications/notifications';
import {
  isInternalUser,
  userRoleIsInRoleAllowedList,
  userTypeIsInAllowedList,
} from 'lib/rolesAndPermissions';
import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SortingConfig } from 'shared/types/SortingConfig';
import { Permission, Role, UserType } from 'shared/types/authorization';
import {
  createNewPortalUser,
  EmailType,
  inviteNewPortalUser,
  sendEmail,
} from 'utils/firebase/cloud-functions';
import {
  BaseUserType,
  HttpsError,
  HttpsErrorDetailsMessageObject,
  SuccessAndMessage,
} from 'utils/firebase/cloud-functions/firebase-functions.types';
import { PortalMemberTableContent } from './PortalMemberTableContent';

export enum PortalMemberTableColumn {
  NAME = 'name',
  MEMBER_SINCE = 'memberSince',
  USER_TYPE = 'userType',
  ROLE = 'role',
  ACTIVE_MANDATES = 'activeMandates',
  ACTIVE_PORTFOLIOS = 'activePortfolios',
}

type Roles = {
  [key: string]: string;
};

export type ToggleUsersToDeleteFunc = (
  userIds: string[],
  toAdd: boolean,
) => void;

export type ADInviteStatus = { success: boolean; inviteURL?: string };

export type ErrorSource = 'AD' | 'firebase';

export const comparer = <T extends Roles | string | number>(
  asc: boolean,
  a: T | undefined,
  b: T | undefined,
  compareFunc: (x: T, y: T) => number,
): number => {
  if (!asc) [a, b] = [b, a];
  if (!a && !b) return 0;
  if (a === undefined) return 1;
  if (b === undefined) return -1;
  return compareFunc(a, b);
};

export const stringComparer = (a: string, b: string): number =>
  a.localeCompare(b);

export const dateStringComparer = (a: string, b: string): number =>
  new Date(a).getTime() - new Date(b).getTime();

const userTypeComparer = (a: UserType, b: UserType): number =>
  +isInternalUser(a) - +isInternalUser(b);

const numberComparer = (a: number, b: number): number => a - b;

export const PortalMembers: React.FC = () => {
  const { state: deletedUsersState } = useLocation() as {
    state: { deletedUserNames?: string[] };
  };
  const {
    user: currentUser,
    role: currentUserRole,
    userType: currentUserType,
  } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(currentUser.id);
  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.principalPri,
  });
  const { userHasPermission } = usePermission();
  const userCanEditMembers = userHasPermission(Permission.USER_ROLE_MGMT_EDIT);
  const userCanViewMembers = userHasPermission(Permission.USER_ROLE_MGMT_VIEW);
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState<
    SortingConfig<PortalMemberTableColumn>
  >({
    column: PortalMemberTableColumn.NAME,
    asc: true,
  });
  const roles = useRoleDefinitions();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUserTypeModal, setShowUserTypeModal] = useState(false);
  const [selectedInternalUser, setSelectedInternalUser] =
    useState<BaseUserType>();
  const [showSelectInternalUserModal, setShowSelectInternalUserModal] =
    useState(false);
  const [errorWhileAddingUser, setErrorWhileAddingUser] = useState<
    string | undefined
  >();
  const { users, setUsersAreUpdated } = useUsers();
  const [userIdsToDelete, setUserIdsToDelete] = useState<string[]>([]);
  const [showDeleteUserModal, setShowDeleteUserModal] =
    useState<boolean>(false);
  const [newUserCreationStatus, setNewUserCreationStatus] = useState<
    string | undefined
  >();

  const availablePortfolios = mandates?.portfolios;

  const sorter = useCallback(
    (
      {
        user: userA,
        role: userArole,
        isGlobalUser: userAisGlobalUser,
      }: BaseUseUserResult,
      {
        user: userB,
        role: userBrole,
        isGlobalUser: userBisGlobalUser,
      }: BaseUseUserResult,
      { column, asc }: SortingConfig<PortalMemberTableColumn>,
      t: TFunction,
      selectedPrincipal = '',
    ): number => {
      // From Robbie:
      // The logic in these case statements could be done much more tidily, but the
      // linter struggles with other structures. If you find a way to tidy this up
      // whilst ensuring a number is returned and that the asc/desc logic is preserved,
      // go for it! :)
      // From Andreas:
      // I would like to restructure PortalMembers, PortalMemberTableContent and PortalMemberTableContentRow entirely
      // PortalMembers should be the only smart component and the table components should be generified as much as possible
      // Done properly we could get rid of all these case statements and make the comparers generic

      switch (column) {
        case PortalMemberTableColumn.NAME: {
          return comparer(asc, userA.name, userB.name, stringComparer);
        }
        case PortalMemberTableColumn.MEMBER_SINCE: {
          const timeA = userA.signedIn ? userA.creationTime : undefined;
          const timeB = userB.signedIn ? userB.creationTime : undefined;
          return comparer(asc, timeA, timeB, dateStringComparer);
        }
        case PortalMemberTableColumn.USER_TYPE: {
          return comparer(
            asc,
            userA.userType,
            userB.userType,
            userTypeComparer,
          );
        }
        case PortalMemberTableColumn.ROLE: {
          const roleComparer = (a: Role, b: Role) => {
            const roleA = t(
              `features:portal-members:labels:roles:${
                a === Role.GLOBAL_USER_EDIT ? Role.GLOBAL_USER_EDIT : a
              }`,
            );
            const roleB = t(
              `features:portal-members:labels:roles:${
                b === Role.GLOBAL_USER_EDIT ? Role.GLOBAL_USER_EDIT : b
              }`,
            );
            return roleA.localeCompare(roleB);
          };
          return comparer(asc, userArole, userBrole, roleComparer);
        }
        case PortalMemberTableColumn.ACTIVE_MANDATES: {
          const a = userA.assignedMandates?.[selectedPrincipal] || 0;
          const b = userB.assignedMandates?.[selectedPrincipal] || 0;
          return comparer(asc, a, b, numberComparer);
        }
        case PortalMemberTableColumn.ACTIVE_PORTFOLIOS: {
          const a = userAisGlobalUser
            ? availablePortfolios?.length
            : userA.mandateFilters?.[selectedPrincipal]?.portfolio.length || 0;
          const b = userBisGlobalUser
            ? availablePortfolios?.length
            : userB.mandateFilters?.[selectedPrincipal]?.portfolio.length || 0;
          return comparer(asc, a, b, numberComparer);
        }
        default:
          return 0;
      }
    },
    [availablePortfolios],
  );

  // We do not want to distinguish between Global User and Global User Edit in the Portal Members Page
  // We want to, however, hide internal users to external users
  const availableRoleOptions: DropdownOptionProps[] = Object.keys(roles)
    .filter((r) => {
      if (r === Role.GLOBAL_USER_EDIT) {
        return false;
      }
      if (
        currentUserType === UserType.EXTERNAL &&
        roles[r as Role].user_type !== UserType.EXTERNAL
      ) {
        return false;
      }
      if (
        currentUserRole === Role.PRINCIPAL_ADMIN &&
        r !== Role.PRINCIPAL_DEVELOPER
      ) {
        return false;
      }
      return true;
    })
    .map((role) => ({
      name: t(`features:portal-members:labels:roles:${role}`),
      value: role,
    }));

  const [rolesToFilter, setRolesToFilter] = useState<
    DropdownOptionProps[] | undefined
  >();

  useEffect(() => {
    if (!availableRoleOptions.length || rolesToFilter) return;
    setRolesToFilter(availableRoleOptions);
  }, [availableRoleOptions, rolesToFilter]);

  useEffect(() => {
    if (userIdsToDelete.length > 0) {
      setUserIdsToDelete([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesToFilter, searchTerm]);

  useEffect(() => {
    // This is to trigger the reloading of users when the selectedPrincipal changes
    if (currentPrincipal) {
      setUserIdsToDelete([]);
      if (availableRoleOptions.length) {
        setRolesToFilter(availableRoleOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPrincipal]);

  const filteredUsers = useMemo(() => {
    return users
      ?.filter(({ user }) => {
        if (searchTerm.length === 0) return true;
        return (
          user.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      .filter((user) => {
        if (!rolesToFilter?.length) return false;
        const role =
          user.role === Role.GLOBAL_USER_EDIT
            ? Role.GLOBAL_USER_VIEW
            : user.role;
        return rolesToFilter.map(({ value }) => value).includes(role || '');
      })
      .sort((a: BaseUseUserResult, b: BaseUseUserResult) =>
        sorter(a, b, sortConfig, t, currentPrincipal.id),
      );
  }, [
    users,
    t,
    currentPrincipal,
    sortConfig,
    searchTerm,
    rolesToFilter,
    sorter,
  ]);

  const handleSortingChange = (targetColumn: PortalMemberTableColumn) => {
    const asc = sortConfig.column === targetColumn ? !sortConfig.asc : true;
    setSortConfig({ column: targetColumn, asc });
  };

  const handleNewUserStepFailure = async (
    message: string,
    newUser: NewUserDetails,
    errorSource: ErrorSource,
  ) => {
    setNewUserCreationStatus(undefined);
    setErrorWhileAddingUser(
      t(`features:portal-members:labels:${message}`, {
        principal: currentPrincipal.name,
        email: newUser.email,
      }),
    );
    const scrollableContainer = document.querySelector('.modal:not(.hidden)');
    if (scrollableContainer?.scrollTop) {
      scrollableContainer.scrollTop = 0;
    }

    await sendEmail(
      generateMailParameter({
        t,
        emailType: EmailType.USER_ONBOARDING_ERROR,
        data: {
          currentUser,
          currentPrincipal,
          newUser,
          errorSource,
          errorMessage: message,
        },
      }),
    ).catch((err) => ({
      data: {
        success: false,
        message: err,
      },
    }));
  };
  const processNewUser = async (newUser: NewUserDetails) => {
    setErrorWhileAddingUser(undefined);
    // 🚨 DEVELOPMENT AID 🚨
    // To bypass the process of adding a user to Active Directory, give them an
    // email domain of @dontaddtoazelisad.com. This will still create the user in
    // firebase, but skip AD, and thus allow us to create users without invitation
    // emails being triggered. We can also create the same user repeatedly (as long
    // as we then delete them). This only works on development or local environments.
    const preventUserInvitation =
      (process.env.REACT_APP_ENVIRONMENT === 'local' ||
        process.env.REACT_APP_ENVIRONMENT === 'development') &&
      newUser.email?.split('@')?.[1] === 'dontaddtoazelisad.com';

    if (!preventUserInvitation) {
      setNewUserCreationStatus('start_creation');
    }

    //Invite user to AD directory and add user to AD groups (general PP + specific principal) if invitation should not be prevented
    const inviteUserRes: { data: SuccessAndMessage | HttpsError } =
      preventUserInvitation
        ? await new Promise((resolve) => {
            resolve({
              data: { success: true, message: 'Invitation to AD is prevented' },
            });
          })
        : await inviteNewPortalUser({
            newUser,
            selectedPrincipalId: currentPrincipal.id,
          }).catch((err) => ({
            data: {
              success: false,
              message: err,
            },
          }));

    if (inviteUserRes.data.success) {
      //AD invitation is prevented or successfully executed
      setNewUserCreationStatus('start_invitation');

      const createUserRes: { data: SuccessAndMessage | HttpsError } =
        await createNewPortalUser({
          newUser,
          selectedPrincipal: currentPrincipal.id,
        }).catch((err) => ({ data: { success: false, message: err } }));

      //AD invitation (if no prevented) and firebase user creation are both successfully executed
      if (createUserRes.data.success) {
        sendEmail(
          generateMailParameter({
            t,
            emailType: EmailType.INVITE,
            data: {
              currentPrincipal,
              currentUser,
              newUser,
              inviteUrl: inviteUserRes.data.inviteRedeemUrl,
            },
          }),
        ).catch((err) => ({
          data: {
            success: false,
            message: err,
          },
        }));

        setErrorWhileAddingUser(undefined);
        setNewUserCreationStatus(undefined);
        setShowAddUserModal(false);
        Notification({
          message: t('features:portal-members:labels:addedNewUser', {
            principal: currentPrincipal.name,
            name: `${newUser.firstName} ${newUser.lastName}`,
          }),
        });

        setUsersAreUpdated(true);
        return;
      } else {
        //Error Handling firebase user creation
        const errorMessage = JSON.parse(
          (createUserRes.data as HttpsError).message.details.message,
        ) as HttpsErrorDetailsMessageObject;
        await handleNewUserStepFailure(
          errorMessage.message || 'Error while creating user',
          newUser,
          'firebase',
        );
      }
    } else {
      //Error Handling AD invitation
      if (
        inviteUserRes.data.message ===
        "this_user_cannot_be_invited_because_the_domain_of_the_user's_email_address_is_a_verified_domain_of_this_directory."
      ) {
        //For @azelis users which are not part of the AD (not fully setup @azelis accounts)
        //This should not happen for regular azelis accounts, but as a error handling for these users we leave the service desk mail as a fallback
        setNewUserCreationStatus('request_service_desk_setup');
        await sendEmail(
          generateMailParameter({
            t,
            emailType: EmailType.SERVICE_DESK,
            data: {
              currentPrincipal,
              currentUser,
              newUser,
            },
          }),
        );

        setShowAddUserModal(false);
        setNewUserCreationStatus(undefined);
        Notification({
          message: t(
            'features:portal-members:labels:user_cannot_be_invited_mailaddress_is_part_of_this_directory',
            {
              principal: currentPrincipal.name,
              name: `${newUser.firstName} ${newUser.lastName}`,
            },
          ),
        });
      } else {
        //handle all other errors (send mail to dpm-admins)
        const errorMessage = JSON.parse(
          (inviteUserRes.data as HttpsError).message.details.message,
        ) as HttpsErrorDetailsMessageObject;

        await handleNewUserStepFailure(errorMessage.message, newUser, 'AD');
        return;
      }
    }
  };

  useEffect(() => {
    if (!deletedUsersState?.deletedUserNames?.length) return;

    const { deletedUserNames } = deletedUsersState;
    const isMulti = deletedUserNames.length > 1;

    Notification({
      message: t(
        `modals:deleteUsersModal:success:${isMulti ? 'multi' : 'single'}`,
        {
          data: isMulti ? deletedUserNames.length : deletedUserNames[0],
        },
      ),
    });

    // This clears the window location state, so the notification doesn't show on reload
    window.history.replaceState({}, document.title);
  }, [deletedUsersState, t]);

  const toggleUsersToDelete: ToggleUsersToDeleteFunc = (userIds, toAdd) => {
    let newUserIdsToDelete = [...userIdsToDelete];
    userIds.forEach((uid) => {
      if (toAdd) {
        newUserIdsToDelete.push(uid);
        return;
      }

      newUserIdsToDelete.splice(newUserIdsToDelete.indexOf(uid), 1);
    });
    newUserIdsToDelete = [...new Set(newUserIdsToDelete)];
    setUserIdsToDelete(newUserIdsToDelete);
  };

  const onUserTypeSelection = (userType: UserType) => {
    setShowUserTypeModal(false);
    switch (userType) {
      case UserType.EXTERNAL:
        setSelectedInternalUser(undefined);
        setShowAddUserModal(true);
        break;
      case UserType.INTERNAL:
        setShowSelectInternalUserModal(true);
        break;
      default:
        break;
    }
  };

  const userIsAllowedToInviteAzelisUsers = userRoleIsInRoleAllowedList(
    currentUserRole,
    [Role.GLOBAL_USER_ADMIN, Role.GLOBAL_USER_EDIT, Role.PDM],
  );

  const userIsAllowedToViewVisibilityBadge = userTypeIsInAllowedList(
    currentUserType,
    [UserType.GLOBAL, UserType.INTERNAL],
  );

  if (filteredUsers === undefined) {
    return (
      <Loading className="flex w-full items-center justify-center" loading />
    );
  }

  return (
    <div className="w-full">
      {userCanEditMembers || userCanViewMembers ? (
        <>
          <div className="w-full px-4 md:px-6">
            <div className="max-w-xl-content mx-auto">
              <div className="mb-7 pt-6 md:mb-9 md:pt-7">
                <div className="flex flex-col items-baseline justify-between sm:flex-row md:space-x-2">
                  <div className="flex items-center justify-start gap-4">
                    <Heading
                      text={t('features:portal-members:heading', {
                        principal: currentPrincipal.name,
                      })}
                      margin={0}
                      level="h1"
                    />
                    {userIsAllowedToViewVisibilityBadge && (
                      <Badge text={t('components:visibilityBadge:text')} />
                    )}
                  </div>
                  {userCanEditMembers && (
                    <div className="my-3 whitespace-nowrap sm:my-0">
                      <Link
                        onClick={(event) => {
                          event.preventDefault();
                          if (userIsAllowedToInviteAzelisUsers) {
                            setShowUserTypeModal(true);
                          } else {
                            setShowAddUserModal(true);
                          }
                        }}
                        label={t(
                          'features:portal-members:labels:inviteNewUser',
                        )}
                        icon="Add"
                        iconPosition="left"
                      />
                    </div>
                  )}
                </div>
                <div className="flex justify-between py-3">
                  <div className="flex items-center">
                    {userCanEditMembers && !!userIdsToDelete?.length && (
                      <>
                        <div className="mr-3">
                          {userIdsToDelete.length}{' '}
                          {t(
                            `features:portal-members:labels:user${
                              userIdsToDelete.length !== 1 ? 's' : ''
                            }Selected`,
                          )}
                        </div>
                        <Link
                          label={t('labels:delete')}
                          icon="Delete"
                          onClick={() => setShowDeleteUserModal(true)}
                        />
                      </>
                    )}
                  </div>
                  <div className="flex">
                    <div className="mr-4 flex flex-row items-baseline">
                      <div className="mr-1 font-medium">
                        {t('features:portal-members:labels:table:role')}
                      </div>
                      <div>
                        <DropdownFilter
                          options={availableRoleOptions}
                          placeholder="Select"
                          initialSelection={rolesToFilter}
                          onChange={(options) => {
                            setRolesToFilter(options);
                          }}
                        />
                      </div>
                    </div>
                    <div className="sm:w-300px w-full">
                      <Input
                        placeholder={t('features:portal-members:labels:search')}
                        iconPosition="right"
                        icon="Search"
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </div>
                <Loading loading={!users}>
                  <PortalMemberTableContent
                    filteredUsers={filteredUsers}
                    sortConfig={sortConfig}
                    handleSortingChange={handleSortingChange}
                    selectedPrinciple={currentPrincipal.id}
                    toggleUsersToDelete={toggleUsersToDelete}
                    userIdsToDelete={userIdsToDelete}
                    availablePortfolios={availablePortfolios}
                    mandates={mandates}
                  />
                </Loading>
              </div>
            </div>
          </div>
          {showUserTypeModal && (
            <SelectUserTypeModal
              onConfirm={onUserTypeSelection}
              onCancel={() => setShowUserTypeModal(false)}
            />
          )}
          {showSelectInternalUserModal && (
            <SelectInternalUser
              selectedPrincipalId={currentPrincipal.id}
              onConfirm={(user) => {
                setSelectedInternalUser(user);
                setShowSelectInternalUserModal(false);
                setShowAddUserModal(true);
              }}
              onCancel={() => setShowSelectInternalUserModal(false)}
            />
          )}
          {showAddUserModal && (
            <AddUserModal
              onCancel={() => {
                setShowAddUserModal(false);
                setErrorWhileAddingUser(undefined);
              }}
              isInputDisabled={!!selectedInternalUser}
              onConfirm={processNewUser}
              errorToShow={errorWhileAddingUser}
              selectedPrincipalId={currentPrincipal.id}
              initialUserData={selectedInternalUser}
            />
          )}
          {newUserCreationStatus && (
            <SavingModal
              topMessage={t('modals:addUserModal:createUserMessage')}
              bottomMessage={t(
                `modals:addUserModal:status:${newUserCreationStatus}`,
              )}
            />
          )}
          {showDeleteUserModal && (
            <DeleteUsersModal
              usersToDelete={userIdsToDelete.map((id) => ({
                id,
                name:
                  filteredUsers?.find(({ user }) => user.id === id)?.user
                    .name || '',
                role:
                  filteredUsers?.find(({ user }) => user.id === id)?.role ||
                  Role.NONE,
              }))}
              selectedPrincipal={currentPrincipal}
              onCancel={() => setShowDeleteUserModal(false)}
              onComplete={() => {
                setShowDeleteUserModal(false);
                setUserIdsToDelete([]);
                setUsersAreUpdated(true);
              }}
            />
          )}
        </>
      ) : (
        <PermissionDenied />
      )}
    </div>
  );
};
