import { LoadingBounce } from 'components/Loading';
import { Modal } from 'components/Modal';
import { useApiKeys } from 'hooks/queries';
import { useScopes } from 'hooks/queries/useScopes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIGeneratorPageContent } from './APIGeneratorPageContent';
import { APIKeyDisplayContent } from './APIKeyDisplayContent';

type APIGeneratorModalProps = {
  onCancel: () => void;
  onConfirmAPICopy: () => void;
};

export enum StepName {
  STEP1 = 'step1',
  STEP2 = 'step2',
}

export const APIGeneratorModal: React.FC<APIGeneratorModalProps> = ({
  onCancel,
  onConfirmAPICopy,
}) => {
  const { t } = useTranslation();
  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [projectName, setProjectName] = useState<string>();
  const [stepNumber, setStepNumber] = useState<StepName>(StepName.STEP1);
  const [isNewApiKeyCopied, setIsNewApiKeyCopied] = useState<boolean>(false);
  const [isApiLoading, setIsApiLoading] = useState<boolean>(false);
  const [newApiKey, setNewApiKey] = useState<string>();
  const {
    data: scopes,
    error: scopesError,
    isLoading: isLoadingScopes,
  } = useScopes();
  const { createApiRecord } = useApiKeys();

  const isConfirmButtonDisabled =
    isApiLoading || stepNumber === StepName.STEP1
      ? !selectedScopes.length || !projectName
      : !isNewApiKeyCopied;

  const onScopesChange = (value: string) => {
    let newSelectedScopes = [...selectedScopes];

    if (selectedScopes.includes(value)) {
      newSelectedScopes.splice(newSelectedScopes.indexOf(value), 1);
    } else {
      newSelectedScopes.push(value);
    }

    newSelectedScopes = [...new Set(newSelectedScopes)];
    setSelectedScopes(newSelectedScopes);
  };

  const handleApiCheckboxChange = (isChecked: boolean) => {
    setIsNewApiKeyCopied(isChecked);
  };

  useEffect(() => {
    if (!newApiKey) return;

    setStepNumber(StepName.STEP2);
    setIsApiLoading(false);
  }, [newApiKey]);

  const onApiKeyGeneration = async () => {
    if (projectName) {
      setIsApiLoading(true);
      const generatedApiKey = await createApiRecord({
        projectName: projectName,
        scopes: selectedScopes,
      });
      setNewApiKey(generatedApiKey.primaryKey);
      setIsApiLoading(false);
    }
  };

  const onApiKeyGenerationFinish = () => {
    if (isNewApiKeyCopied) {
      setStepNumber(StepName.STEP1);
      onConfirmAPICopy();
    }
  };

  return (
    <Modal
      confirmText={t(
        `features:data-connection:apiKeyGenerator:apiModal:${stepNumber}:proceed`,
      )}
      heading={t(
        `features:data-connection:apiKeyGenerator:apiModal:${stepNumber}:heading`,
      )}
      headingAlign="left"
      icon={stepNumber === StepName.STEP2 ? 'Activation' : undefined}
      isBig
      isCancelButtonHidden
      isCloseIconHidden={stepNumber === StepName.STEP2}
      isConfirmButtonAlignedRight
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      isIconAlignedCenter
      onCancel={onCancel}
      onConfirm={
        stepNumber === StepName.STEP1
          ? onApiKeyGeneration
          : onApiKeyGenerationFinish
      }
    >
      {stepNumber === StepName.STEP1 && (
        <>
          {isLoadingScopes && <LoadingBounce />}
          {scopesError && (
            <div>
              {t(
                `features:data-connection:apiKeyGenerator:apiModal:errors:${scopesError.message}`,
              )}
            </div>
          )}
          {scopes?.length && (
            <APIGeneratorPageContent
              isApiLoading={isApiLoading}
              availableScopes={scopes}
              onScopesChange={onScopesChange}
              handleProjectNameChange={setProjectName}
            />
          )}
        </>
      )}
      {stepNumber === StepName.STEP2 && newApiKey && (
        <APIKeyDisplayContent
          handleAPICheckboxChange={handleApiCheckboxChange}
          newAPIKey={newApiKey}
        />
      )}
    </Modal>
  );
};
